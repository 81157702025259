import langIcon from "../Image/englishA.png";
import usaIcon from "../Image/Icons/USA_Flag_Icon.png";
import spainIcon from "../Image/Icons/Spain_flag_Icon.png";

//import { Link,useMatch,useResolvedPath } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
const Navbar = () => {
    const [showMediaIcons, setShowMediaIcons] = useState(false);
    const stopClickPropagation = event => event.stopPropagation();
    const [lang, setLang] = useState('us');
    const { t, i18n } = useTranslation("translation");
    const changeLang = () => {
        setLang('us');
        i18n.changeLanguage('en')
    }
    const changeLanges = () => {
        setLang('sp')
        i18n.changeLanguage('sp')

    }
    return (
        <>
            <nav className="main-nav">
                {/* 1st logo part  */}
                <div className="logo">
                    <h3>
                        <NavLink to="/"> <span>C</span>RUISE
                            <span>Q</span>UESTS</NavLink>
                    </h3>
                </div>
                {/* 2nd menu part  */}
                <div
                    className={
                        showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
                    }
                >
                    <ul>
                        <li>
                            <NavLink to="/">{t('Home')}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/About">{t('A')}</NavLink>
                        </li>
                        {/*<li>*/}
                        {/*    */}{/*<NavLink to="/Beforeyoufly">BEFORE YOU FLY</NavLink>*/}
                        {/*    <NavDropdown title={t('B')} id="btnbeforeyoufly">*/}
                        {/*        <NavDropdown.Item as={NavLink} to="/OfacRequirements" onClick={stopClickPropagation}>{t('OFAC')}</NavDropdown.Item>*/}
                        {/*        <NavDropdown.Item as={NavLink} to="/CubaEntryRequiremenets" onClick={stopClickPropagation}>{t('CUBA')}</NavDropdown.Item>*/}
                        {/*        <NavDropdown.Item as={NavLink} to="/BaggagePolicy" onClick={stopClickPropagation}>{t('BAGGAGE')}</NavDropdown.Item>                                */}
                        {/*        <NavDropdown.Item as={NavLink} to="/AtTheAirportLink" onClick={stopClickPropagation}>{t('AIRPORT')}</NavDropdown.Item>*/}
                        {/*    </NavDropdown>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink to="/Contact">{t('C')}</NavLink>
                        </li>
                        <li>
                            <NavDropdown to="/" title={<span> <img
                                src={lang === 'us' ? usaIcon : spainIcon}
                                className="usaIcon"
                                alt=""
                                style={{ width: "24px", height: "17px" }}
                            /></span>}>

                                <NavDropdown.Item to="/" onClick={changeLang} title={"a"} style={{width:"52px"} } ><span> <img
                                    src={usaIcon}
                                    className="usaIcon"
                                    alt=""
                                    style={{ width: "24px", height: "17px" }}
                                /></span></NavDropdown.Item>
                                <NavDropdown.Item to="/" onClick={changeLanges} title=""><span> <img
                                    src={spainIcon}
                                    className="spainIcon"
                                    alt=""
                                    style={{ width: "24px", height: "17px" }}
                                /></span></NavDropdown.Item>
                            </NavDropdown>
                        </li>
                    </ul>
                </div>
                <div className="hamburger-menu">
                    <NavLink to="/" onClick={() => setShowMediaIcons(!showMediaIcons)}>
                        <GiHamburgerMenu />
                    </NavLink>
                </div>
            </nav>
        </>
    );
};
export default withTranslation('translation')(Navbar);
