import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MainURL from "../AirportList/MainUrl";
import Popup from "reactjs-popup";
import SmithFooter from "../AirportList/SmithFooter";
import RtffFFooter from "../AirportList/RtffFooter";
export function PaymentCheckOut() {
  const loaction = useLocation();

  const navigate = useNavigate();
  let bookingPNR = [];
  let deprtFlightId = [];
  let retFlightId = [];
  const [allLocation] = useState(loaction);
  const [liveUrl] = useState(MainURL[0]);
  let paxName = allLocation.state.name;
  //let fare = allLocation.state.fare;
  bookingPNR = allLocation.state.bookingPNR;
  let depart = allLocation.state.depart;
  let retFerry = allLocation.state.retFerry;
  let company = allLocation.state.company;
  let bookingDate = allLocation.state.ferryDate;
  deprtFlightId = allLocation.state.flightId;
  retFlightId = allLocation.state.retFlightId;
  let paymentAmount = allLocation.state.paxFareAmount;
  let companyID = allLocation.state.Id;
  let email = allLocation.state.email;
  const optMonth = { month: "long" };
  const optDay = { day: "numeric" };
  const optWeekdays = { weekday: "long" };
  const newDate = new Date(bookingDate);
  const weekDay = newDate
    .toLocaleDateString("en-US", optWeekdays)
    .toUpperCase();
  const monthDay = newDate.toLocaleDateString("en-US", optMonth).toUpperCase();
  const dateDay = newDate.toLocaleDateString("en-US", optDay).toUpperCase();
  const [formOfPayment, setFormOfPayment] = useState("");
  const [country, setCountry] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [validThru, setValidThru] = useState("");
  const [csvNumber, setCSVNumber] = useState("");
  const [totalAmount, setFare] = useState(paymentAmount);
  const [PassenegrName, setPassenegrName] = useState("");
  const [city, setCity] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const handleCity = (event) => {
    setCity(event.target.value);
  };
  const handleName = (event) => {
    setPassenegrName(event.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleFormOfPayment = (event) => {
    setFormOfPayment(event.target.value);
  };
  const handleCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleCardNumber = (event) => {
    setCardNumber(event.target.value);
  };
  const handleValidThru = (event) => {
    setValidThru(event.target.value);
  };
  const handleCSV = (event) => {
    setCSVNumber(event.target.event);
  };
  const hanldeFare = (event) => {
    setFare(event.target.value);
  };

  function validThruDate() {
    var expire_date = document.getElementById("txtValidThru").value;
    if (expire_date.length === 2) {
      document.getElementById("txtValidThru").value = expire_date + "/";
    }
  }
  const OnPaymentCheckOut = (event) => {
    // Check if the Card Number is an Empty string or not.
    event.preventDefault();

    if (cardNumber.length === 0) {
      alert("Invalid Form, Card number can not be empty");
      return;
    }
    if (validThru.length === 0) {
      alert("Invalid Form, Valid Thru can not be empty");
      return;
    }
    let datevalue = validThru;
    var month = datevalue.split("/")[0];
    var year = datevalue.split("/")[1];
    // if (csvNumber.length === 0) {
    // alert("Invalid Form, Valid Thru can not be empty");
    // return;
    //}
    if (PassenegrName.length === 0) {
      alert("Invalid Form, First Name can not be empty");
      return;
    }
    if (lastName.length === 0) {
      alert("Invalid Form, Last Name can not be empty");
      return;
    }
    if (city.length === 0) {
      alert("Invalid Form, City Name can not be empty");
      return;
    }

    var raw = JSON.stringify({
      amount: paymentAmount,
      ccCharge: 0,
      pnr: bookingPNR,
      ccNumber: cardNumber,
      ccFirstName: PassenegrName,
      ccLastName: lastName,
      ccExpMonth: month,
      ccExpYear: year,
      company: companyID,
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setIsLoadingPayment(true);
    fetch(liveUrl.liveUrl + "Payment/SavePayment", requestOptions)
      .then((response) => {
        response.json().then((result) => {
          if (
            response.status === 200 &&
            (result.data.transactionid !== undefined ||
              result.data.Transactionid !== undefined) &&
            (result.data.receipt !== undefined ||
              result.data.Receipt !== undefined)
          ) {
            let htmlStringRec = result.data.receipt;
            setTimeout(() => {
              setIsLoadingPayment(false);
            }, 2000);
            navigate("/PaymentConfirmation", {
              replace: true,
              state: {
                htmlStringRec,
                email,
              },
            });
          } else {
            setIsLoadingPayment(false);
            alert("Could not be able to process credit card please try again.");
          }
        });
      })
      .catch((error) => console.log("error", error));
  };
  //navigate("/PaymentConfirmation");
  return (
    <>
      <Navbar />
      <Container>
        <Card style={{ borderRadius: "2px" }}>
          <Card.Title style={{ fontWeight: "bolder", textAlign: "center" }}>
            Payment Check-Out
          </Card.Title>
          <hr />
          <div className="row">
            <div className="col-sm-4">
              <strong>Name</strong>
              <br />
              {paxName}
            </div>
            <div className="col-sm-4">
              <strong>Date</strong>
              <br />
              {weekDay}&nbsp;
              {dateDay}&nbsp; {monthDay}
            </div>
            <div className="col-sm-4">
              <strong>Company</strong>
              <br /> {company}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-4">
              <strong>Departure Flight</strong>
              <br />
              {depart}
            </div>
            <div className="col-sm-4">
              <strong>Return Flight</strong>
              <br /> {retFerry}
            </div>
            <div className="col-sm-4">
              <strong>Fare</strong>
              <br />
              {"$" + paymentAmount}
            </div>
          </div>

          <hr />
          <div className="row">
            {/* bag */}
            <div className="col-sm-3">
              <Popup
                contentStyle={{
                  width: "50%",
                  background: "rgb(2, 36, 68)",
                  color: "white",
                }}
                trigger={
                  <div
                    className=" form-control"
                    style={{
                      cursor: "pointer",
                      background: "none",
                      border: "none",
                      marginLeft: "51%",
                    }}
                  >
                    <strong>Baggage</strong>
                  </div>
                }
                position="top"
              >
                <div className="row" style={{ color: "white" }}>
                  <div className="col-sm-12">
                    <h4>BAGGAGE:</h4>
                  </div>

                  <div
                    className="col-sm-12"
                    style={{
                      textAlign: "justify",

                      color: "white",
                    }}
                  >
                    The carrier allows each passenger to check up to 10 bags no
                    more than 50 pounds each. Each checked bag will be charged a
                    fee, 10 dollars for the first 3 bags and 10 dollars
                    thereafter for each additional bag. Surf boards and other
                    bulky items are charged at a fee dependent on the size and
                    weight. Smith's Ferry does not take responsibility for lost
                    or damaged bags before, during or after the voyage.
                  </div>
                </div>
              </Popup>
            </div>
            {/* //Cancel */}
            <div className="col-sm-2">
              <Popup
                contentStyle={{
                  width: "51%",
                  background: "rgb(2, 36, 68)",
                  color: "white",
                }}
                trigger={
                  <div
                    className="form-control"
                    style={{
                      cursor: "pointer",
                      background: "none",
                      border: "none",
                    }}
                  >
                    and <strong>Cancellation Policy</strong>
                  </div>
                }
                position="top"
              >
                <div className="row">
                  <div className="col-sm-12">
                    <h4>CANCELLATION AND REFUND:</h4>
                  </div>
                  <div className="col-sm-12">
                    <h5 style={{ color: "lightsalmon" }}>
                      You have purchased a NON-REFUNDABLE and NON-TRANSFERABLE
                      ticket.
                    </h5>
                  </div>
                  <div className="col-sm-12" style={{ textAlign: "justify" }}>
                    WE HAVE NO RIGHT TO CANCEL THE FERRY LESS THAN 10 DAYS
                    BEFORE DEPARTURE EXCEPT IN CIRCUMSTANCES THAT MAKE IT
                    PHYSICALLY IMPOSSIBLE TO PERFORM THE FERRY TRIP. IF THIS
                    OCCURS, WE WILL NOTIFY YOU AS SOON AS POSSIBLE BUT NOT LATER
                    THAN THE SCHEDULED DEPARTURE DATE. IF THE FERRY IS
                    CANCELLED, A FULL REFUND WILL BE MADE TO YOU WITHIN 14 DAYS
                    AFTER CANCELLATION.
                  </div>
                </div>
              </Popup>
              {/* //End Cancel */}
            </div>
          </div>

          <hr />
          <div className="row">
            <div
              className="col-sm-12"
              style={{
                textAlign: "justify",
                width: "64%",
                marginLeft: "18%",
                background: "rgb(2, 36, 68)",
                color: "white",
              }}
            >
              <strong>NOTE:</strong> It is important that the billing address
              and phone number you provide are the address and phone number that
              your credit card company has on file for you. We will not be able
              to process your order if the information provided does not match.
            </div>
          </div>
          <br />
          <form id="form1">
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="form-floating col-sm-4">
                <select
                  className="form-select"
                  id="ddlFormofPayment"
                  name="ddlFormofPayment"
                  onChange={handleFormOfPayment}
                  aria-label="Floating label select example"
                  required
                >
                  <option value="VI">Visa</option>
                  <option value="MC">MasterCard</option>
                </select>
                <label for="floatingSelectGrid">Form of Payment</label>
              </div>
              <div className="form-floating col-sm-4">
                <select
                  className="form-select"
                  id="ddlCountry"
                  name="ddlCountry"
                  onChange={handleCountry}
                  aria-label="Floating label select example"
                  required
                >
                  <option value="US">USA (United States)</option>
                  <option value="GB">GBR (United Kingdom)</option>
                  <option value="VI">VIR (Virgin Islands, U.S.)</option>
                  <option value="VG">VGB (Virgin Islands, British)</option>
                  <option value="AF">AFG (Afghanistan)</option>
                  <option value="AL">ALB (Albania)</option>
                  <option value="DZ">DZA (Algeria)</option>
                  <option value="AS">ASM (American Samoa)</option>
                  <option value="AD">AND (Andorra)</option>
                  <option value="AO">AGO (Angola)</option>
                  <option value="AI">AIA (Anguilla)</option>
                  <option value="AG">ATG (Antigua and Barbuda)</option>
                  <option value="AR">ARG (Argentina)</option>
                  <option value="AM">ARM (Armenia)</option>
                  <option value="AW">ABW (Aruba)</option>
                  <option value="AU">AUS (Australia)</option>
                  <option value="AT">AUT (Austria)</option>
                  <option value="AZ">AZE (Azerbaijan)</option>
                  <option value="BS">BHS (Bahamas)</option>
                  <option value="BH">BHR (Bahrain)</option>
                  <option value="BD">BGD (Bangladesh)</option>
                  <option value="BB">BRB (Barbados)</option>
                  <option value="BY">BLR (Belarus)</option>
                  <option value="BE">BEL (Belgium)</option>
                  <option value="BZ">BLZ (Belize)</option>
                  <option value="BJ">BEN (Benin)</option>
                  <option value="BM">BMU (Bermuda)</option>
                  <option value="BT">BTN (Bhutan)</option>
                  <option value="BO">BOL (Bolivia)</option>
                  <option value="BA">BIH (Bosnia and Herzegovina)</option>
                  <option value="BW">BWA (Botswana)</option>
                  <option value="BR">BRA (Brazil)</option>
                  <option value="BN">BRN (Brunei Darussalam)</option>
                  <option value="BG">BGR (Bulgaria)</option>
                  <option value="BF">BFA (Burkina Faso)</option>
                  <option value="BI">BDI (Burundi)</option>
                  <option value="KH">KHM (Cambodia)</option>
                  <option value="CM">CMR (Cameroon)</option>
                  <option value="CA">CAN (Canada)</option>
                  <option value="CV">CPV (Cape Verde)</option>
                  <option value="KY">CYM (Cayman Islands)</option>
                  <option value="CF">CAF (Central African Republic)</option>
                  <option value="TD">TCD (Chad)</option>
                  <option value="CL">CHL (Chile)</option>
                  <option value="CN">CHN (China)</option>
                  <option value="CO">COL (Colombia)</option>
                  <option value="KM">COM (Comoros)</option>
                  <option value="CD">COD (Congo (DRC))</option>
                  <option value="CG">COG (Congo (Republic of))</option>
                  <option value="CK">COK (Cook Islands)</option>
                  <option value="CR">CRI (Costa Rica)</option>
                  <option value="CI">CIV (Côte d'Ivoire)</option>
                  <option value="HR">HRV (Croatia)</option>
                  <option value="CU">CUB (Cuba)</option>
                  <option value="CW">CUW (Curaçao)</option>
                  <option value="CY">CYP (Cyprus)</option>
                  <option value="CZ">CZE (Czech Republic)</option>
                  <option value="DK">DNK (Denmark)</option>
                  <option value="DJ">DJI (Djibouti)</option>
                  <option value="DM">DMA (Dominica)</option>
                  <option value="DO">DOM (Dominican Republic)</option>
                  <option value="EC">ECU (Ecuador)</option>
                  <option value="EG">EGY (Egypt)</option>
                  <option value="SV">SLV (El Salvador)</option>
                  <option value="GQ">GNQ (Equatorial Guinea)</option>
                  <option value="ER">ERI (Eritrea)</option>
                  <option value="EE">EST (Estonia)</option>
                  <option value="ET">ETH (Ethiopia)</option>
                  <option value="FK">FLK (Falkland Islands (Malvinas))</option>
                  <option value="FO">FRO (Faroe Islands)</option>
                  <option value="FJ">FJI (Fiji)</option>
                  <option value="FI">FIN (Finland)</option>
                  <option value="FR">FRA (France)</option>
                  <option value="GF">GUF (French Guiana)</option>
                  <option value="PF">PYF (French Polynesia)</option>
                  <option value="GA">GAB (Gabon)</option>
                  <option value="GM">GMB (Gambia)</option>
                  <option value="GE">GEO (Georgia)</option>
                  <option value="DE">DEU (Germany)</option>
                  <option value="GH">GHA (Ghana)</option>
                  <option value="GI">GIB (Gibraltar)</option>
                  <option value="GR">GRC (Greece)</option>
                  <option value="GL">GRL (Greenland)</option>
                  <option value="GD">GRD (Grenada)</option>
                  <option value="GP">GLP (Guadeloupe)</option>
                  <option value="GU">GUM (Guam)</option>
                  <option value="GT">GTM (Guatemala)</option>
                  <option value="GG">GGY (Guernsey)</option>
                  <option value="GN">GIN (Guinea)</option>
                  <option value="GW">GNB (Guinea-Bissau)</option>
                  <option value="GY">GUY (Guyana)</option>
                  <option value="HT">HTI (Haiti)</option>
                  <option value="VA">
                    VAT (Holy See (Vatican City State))
                  </option>
                  <option value="HN">HND (Honduras)</option>
                  <option value="HK">HKG (Hong Kong)</option>
                  <option value="HU">HUN (Hungary)</option>
                  <option value="IS">ISL (Iceland)</option>
                  <option value="IN">IND (India)</option>
                  <option value="ID">IDN (Indonesia)</option>
                  <option value="IR">IRN (Iran)</option>
                  <option value="IQ">IRQ (Iraq)</option>
                  <option value="IE">IRL (Ireland)</option>
                  <option value="IL">ISR (Israel)</option>
                  <option value="IT">ITA (Italy)</option>
                  <option value="JM">JAM (Jamaica)</option>
                  <option value="JP">JPN (Japan)</option>
                  <option value="JO">JOR (Jordan)</option>
                  <option value="KZ">KAZ (Kazakhstan)</option>
                  <option value="KE">KEN (Kenya)</option>
                  <option value="KI">KIR (Kiribati)</option>
                  <option value="KW">KWT (Kuwait)</option>
                  <option value="KG">KGZ (Kyrgyzstan)</option>
                  <option value="LA">LAO (Laos)</option>
                  <option value="LV">LVA (Latvia)</option>
                  <option value="LB">LBN (Lebanon)</option>
                  <option value="LS">LSO (Lesotho)</option>
                  <option value="LR">LBR (Liberia)</option>
                  <option value="LY">LBY (Libya)</option>
                  <option value="LI">LIE (Liechtenstein)</option>
                  <option value="LT">LTU (Lithuania)</option>
                  <option value="LU">LUX (Luxembourg)</option>
                  <option value="MO">MAC (Macao)</option>
                  <option value="MK">MKD (Macedonia)</option>
                  <option value="MG">MDG (Madagascar)</option>
                  <option value="MW">MWI (Malawi)</option>
                  <option value="MY">MYS (Malaysia)</option>
                  <option value="MV">MDV (Maldives)</option>
                  <option value="ML">MLI (Mali)</option>
                  <option value="MT">MLT (Malta)</option>
                  <option value="MH">MHL (Marshall Islands)</option>
                  <option value="MQ">MTQ (Martinique)</option>
                  <option value="MR">MRT (Mauritania)</option>
                  <option value="MU">MUS (Mauritius)</option>
                  <option value="YT">MYT (Mayotte)</option>
                  <option value="MX">MEX (Mexico)</option>
                  <option value="FM">
                    FSM (Micronesia, Federated States of)
                  </option>
                  <option value="MD">MDA (Moldova, Republic of)</option>
                  <option value="MC">MCO (Monaco)</option>
                  <option value="MN">MNG (Mongolia)</option>
                  <option value="ME">MNE (Montenegro)</option>
                  <option value="MS">MSR (Montserrat)</option>
                  <option value="MA">MAR (Morocco)</option>
                  <option value="MZ">MOZ (Mozambique)</option>
                  <option value="MM">MMR (Myanmar)</option>
                  <option value="NA">NAM (Namibia)</option>
                  <option value="NR">NRU (Nauru)</option>
                  <option value="NP">NPL (Nepal)</option>
                  <option value="NL">NLD (Netherlands)</option>
                  <option value="NC">NCL (New Caledonia)</option>
                  <option value="NZ">NZL (New Zealand)</option>
                  <option value="NI">NIC (Nicaragua)</option>
                  <option value="NE">NER (Niger)</option>
                  <option value="NG">NGA (Nigeria)</option>
                  <option value="NF">NFK (Norfolk Island)</option>
                  <option value="MP">MNP (Northern Mariana Islands)</option>
                  <option value="NO">NOR (Norway)</option>
                  <option value="OM">OMN (Oman)</option>
                  <option value="PK">PAK (Pakistan)</option>
                  <option value="PW">PLW (Palau)</option>
                  <option value="PS">PSE (Palestine, State of)</option>
                  <option value="PA">PAN (Panama)</option>
                  <option value="PG">PNG (Papua New Guinea)</option>
                  <option value="PY">PRY (Paraguay)</option>
                  <option value="PE">PER (Peru)</option>
                  <option value="PH">PHL (Philippines)</option>
                  <option value="PN">PCN (Pitcairn)</option>
                  <option value="PL">POL (Poland)</option>
                  <option value="PT">PRT (Portugal)</option>
                  <option value="PR">PRI (Puerto Rico)</option>
                  <option value="QA">QAT (Qatar)</option>
                  <option value="RE">REU (Réunion)</option>
                  <option value="RO">ROU (Romania)</option>
                  <option value="RU">RUS (Russian Federation)</option>
                  <option value="RW">RWA (Rwanda)</option>
                  <option value="BL">BLM (Saint Barthélemy)</option>
                  <option value="SH">SHN (Saint Helena)</option>
                  <option value="KN">KNA (Saint Kitts and Nevis)</option>
                  <option value="LC">LCA (Saint Lucia)</option>
                  <option value="MF">MAF (Saint Martin)</option>
                  <option value="PM">SPM (Saint Pierre and Miquelon)</option>
                  <option value="VC">
                    VCT (Saint Vincent and the Grenadines)
                  </option>
                  <option value="WS">WSM (Samoa)</option>
                  <option value="SM">SMR (San Marino)</option>
                  <option value="ST">STP (Sao Tome and Principe)</option>
                  <option value="SA">SAU (Saudi Arabia)</option>
                  <option value="SN">SEN (Senegal)</option>
                  <option value="RS">SRB (Serbia)</option>
                  <option value="SC">SYC (Seychelles)</option>
                  <option value="SL">SLE (Sierra Leone)</option>
                  <option value="SG">SGP (Singapore)</option>
                  <option value="SX">SXM (Sint Maarten)</option>
                  <option value="SK">SVK (Slovakia)</option>
                  <option value="SI">SVN (Slovenia)</option>
                  <option value="SB">SLB (Solomon Islands)</option>
                  <option value="SO">SOM (Somalia)</option>
                  <option value="ZA">ZAF (South Africa)</option>
                  <option value="KR">KOR (South Korea)</option>
                  <option value="SS">SSD (South Sudan)</option>
                  <option value="ES">ESP (Spain)</option>
                  <option value="LK">LKA (Sri Lanka)</option>
                  <option value="SD">SDN (Sudan)</option>
                  <option value="SR">SUR (Suriname)</option>
                  <option value="SZ">SWZ (Swaziland)</option>
                  <option value="SE">SWE (Sweden)</option>
                  <option value="CH">CHE (Switzerland)</option>
                  <option value="SY">SYR (Syria)</option>
                  <option value="TW">TWN (Taiwan)</option>
                  <option value="TJ">TJK (Tajikistan)</option>
                  <option value="TZ">TZA (Tanzania)</option>
                  <option value="TH">THA (Thailand)</option>
                  <option value="TL">TLS (Timor-Leste)</option>
                  <option value="TG">TGO (Togo)</option>
                  <option value="TK">TKL (Tokelau)</option>
                  <option value="TO">TON (Tonga)</option>
                  <option value="TT">TTO (Trinidad and Tobago)</option>
                  <option value="TN">TUN (Tunisia)</option>
                  <option value="TR">TUR (Turkey)</option>
                  <option value="TM">TKM (Turkmenistan)</option>
                  <option value="TC">TCA (Turks and Caicos Islands)</option>
                  <option value="TV">TUV (Tuvalu)</option>
                  <option value="UG">UGA (Uganda)</option>
                  <option value="UA">UKR (Ukraine)</option>
                  <option value="AE">ARE (United Arab Emirates)</option>
                  <option value="UY">URY (Uruguay)</option>
                  <option value="UZ">UZB (Uzbekistan)</option>
                  <option value="VU">VUT (Vanuatu)</option>
                  <option value="VE">VEN (Venezuela)</option>
                  <option value="VN">VNM (Viet Nam)</option>
                  <option value="EH">ESH (Western Sahara)</option>
                  <option value="YE">YEM (Yemen)</option>
                  <option value="ZM">ZMB (Zambia)</option>
                  <option value="ZW">ZWE (Zimbabwe)</option>
                </select>
                <label for="floatingSelectGrid">Country</label>
              </div>
              <div className="col-sm-2"></div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="form-floating col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="txtCardNumber"
                  name="txtCardNumber"
                  onChange={handleCardNumber}
                  placeholder="xxx-xxxx-xxxxx"
                  maxLength="16"
                  required
                />
                <label for="floatingCardNumber">Card Number</label>
              </div>
              <div className="form-floating col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="txtValidThru"
                  name="txtValidThru"
                  onKeyPress={validThruDate}
                  onChange={handleValidThru}
                  placeholder="mm/yyyy"
                  maxLength="7"
                  required
                />
                <label for="floatingValid">Valid Thru</label>
              </div>
              <div className="col-sm-2"></div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="form-floating col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="txtCSV"
                  name="txtCSV"
                  onChange={handleCSV}
                  placeholder="CSV"
                  maxLength="6"
                />
                <label for="floatingCSV">CSV</label>
              </div>
              <div className="form-floating col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="txtfare"
                  name="txtfare"
                  value={"$" + paymentAmount}
                  onChange={hanldeFare}
                  placeholder="Fare"
                  readOnly
                />
                <label for="floatingFare">Fare</label>
              </div>
              <div className="col-sm-2"></div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="form-floating col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="txtName"
                  name="txtName"
                  onChange={handleName}
                  placeholder="First Name"
                  required
                />
                <label htmlFor="floatingName">First Name</label>
              </div>
              <div className="form-floating col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="txtLastName"
                  name="txtLastName"
                  onChange={handleLastName}
                  placeholder="Name"
                  required
                />
                <label htmlFor="floatingName">Last Name</label>
              </div>
              <div className="col-sm-2"></div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="form-floating col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="txtcity"
                  name="txtcity"
                  onChange={handleCity}
                  placeholder="City"
                />
                <label for="floatingCity">City</label>
              </div>
              <div className="form-floating col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  id="txtState"
                  name="txtState"
                  placeholder="State"
                />
                <label for="floatingState">State</label>
              </div>
              <div className="col-sm-2"></div>
            </div>
            <br />
            <div className="row">
              <div
                className="col-sm-12"
                style={{
                  textAlign: "justify",
                  width: "64%",
                  marginLeft: "18%",
                  background: "rgb(2, 36, 68)",
                  color: "white",
                }}
              >
                I have carefully reviewed my itinerary (including dates, cities,
                and travel times) and am ready to proceed with this purchase. I
                understand that all tickets are non-refundable once purchased
                and agree to all other
                <br />{" "}
                <strong>
                  I authorize a charge of {"$" + paymentAmount} to my credit
                  card.
                </strong>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-12">
                <button
                  type="submit"
                  className="btn btn-secondary btn-lg"
                  onClick={OnPaymentCheckOut}
                  style={{ background: "rgb(2, 36, 68)" }}
                >
                  Pay With Credit Card
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {company === "Smith's Ferry" ? (
                  <SmithFooter />
                ) : (
                  <RtffFFooter />
                )}
              </div>
            </div>
            {isLoadingPayment ? (
              <div>
                <img
                  className="overlay spinner"
                  style={{ width: "150px", height: "150px" }}
                  src="./Payment.gif"
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
          </form>
          <br />
        </Card>
      </Container>
    </>
  );
}
export default PaymentCheckOut;
