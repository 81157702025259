

if (typeof String.prototype.empty !== "function") {
    String.prototype.ToUSCurrencyFormat = function () {

        var val = parseFloat(this);
        val = val.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return val;
    };
}

window.onload = function (e) {

    //alert('a');
    ////debugger;
    //var d = getFlightData1();
    //console.log(d);

    

}

const getFlightData1 = () => {
    //debugger;
    var sample = require('../../Files/JSONData/Flightlist.json');
    console.log(sample);
    return sample; };


