import "../css/aboutStyle.css";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card } from "react-bootstrap";
export default function BaggagePolicy() {
  return (
    <>
      <div className="App">
        <Navbar />
        <Container>
          <Card>
            <Card.Body>
              <Card.Title>
                <h1 style={{ marginRight: "64%" }}>BAGGAGE POLICY</h1>
              </Card.Title>

              <Card.Text>
                <p style={{ textAlign: "justify" }}>
                  Travel to Cuba for tourist activities remains prohibited by
                  statute. However, the Department of Treasury�s Office of
                  Foreign Assets Control (OFAC) has issued general licenses for
                  12 categories of travel. The 12 categories of authorized
                  travel to Cuba are:
                </p>

                <ol style={{ listStyleType: "decimal" }}>
                  <li>Family visits</li>
                </ol>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <div
            className="row"
            style={{ backgroundColor: "rgb(2, 36, 68)", marginTop: "31%" }}
          >
            <div
              className="col-sm-12"
              style={{
                color: "white",
                fontFamily: "LufgaRegular",
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              {" "}
              © 2023 Copyright All Rights Reserved, Airmax System
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
