import "./css/App.css";
import "./css/NavbarStyle.css";
import NavBar from "./components/Navbar";
//import FlightSearch from './FlightSearch';
import "./css/FlightSearchStyle.css";
//import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./js/CommonJs/js_CommonJs.js";
import FormControls from "./components/FlightFormControls";
import { useTranslation } from "react-i18next";
import FooterItem from "./components/FooterItem";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";

function App() {
  const { t } = useTranslation("translation");
  return (
    <>
      <div className="App">
        <NavBar />
        <div>
          <div className="middlecontent">
            <div className="ax-middlecontent">
              <h2 className="ax-sub-middlecontent" style={{ display: "none" }}>
                <strong className="ax-colorPrimary">{t("MainContent")}</strong>
              </h2>
            </div>
            <div className="ax-middlecontent">
              <h2 className="ax-sub-middlecontent" style={{ display: "none" }}>
                <em className="ax-colorPrimary">{t("MainContent1")}</em>
              </h2>
            </div>
          </div>
          {/** For Flight search div */}
          <div className="container">
            <Container>
              <Card>
                <FormControls />
              </Card>
            </Container>
          </div>
        </div>

        <div className="row" style={{ width: "100%", marginTop: "6%" }}>
          <div className="col-lg-12" style={{ textAlign: "center" }}>
            {" "}
            <FooterItem />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
