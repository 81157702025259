import React from "react";
import { useNavigate } from "react-router-dom";
export default function SmithFooter() {
  const navigate = useNavigate();
  const OnPaymentCheckOut = (event) => {
    event.preventDefault();
    navigate("/");
  };
  return (
    <>
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          Please check the confirmation carefully. If any item does not meet
          your satisfaction, please contact Smith's Ferry reservations at
          1-340-775-7292. Please be prepared to specify your Confirmation
          Number.
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12" style={{ textAlign: "center" }}>
          OPERATOR - PARTICIPANT CONTRACT
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          This Agreement sets Forth the terms and conditions under which we,
          Smith's Ferry, 3400 Veterans Drive, St. Thomas, VI 00802, in return
          for payment of the amount indicated as the total ferry price, agree to
          provide this ferry.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>CHECK-IN:</strong> Passengers are required to check in a
          minimum of 45 minutes prior to departure. Carrier reserves the right
          to consider passenger as a NO SHOW and will accommodate stand-by
          passengers 15 minutes prior to departure.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>CANCELLATION AND REFUND:</strong> You have purchased a
          NON-REFUNDABLE and NON-TRANSFERABLE ticket.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>RESPONSIBILITY:</strong> We, as the principal, are responsible
          to you for arranging the ferry provided, however, that in the absence
          of negligence on our part, we are not responsible for personal injury
          or damage caused by the water carrier or other suppliers of any of the
          services offered in connections with the ferry.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>RESERVATIONS AND PAYMENTS:</strong> Attached to this agreement
          is your reservation detail. The ferry reservation is confirmed and
          fully booked. The ticket will be issued at the time of check in at the
          designated departing dock. Carrier may refuse transportation of the
          applicable fare has not been paid.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>FERRY PRICE:</strong> The Ferry price represents your cost for
          a ferry that departs to and/or from as indicated above, .USVI Port Fee
          ($10.00), BVI Environmental Fee ($10.00), BVI Departure Tax ($20.00)
          are NOT included in the ferry price. Please note that tax amounts are
          subject to change as regulated by the government agencies.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>FERRY:</strong> This service will be performed by Smith's
          Ferry or an approved company by Smith's Ferry. This water carrier
          reserves the right to substitute equivalent ferry, if necessary.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>BAGGAGE:</strong> The carrier allows each passenger to check
          up to 7 bags, no more than 49 pounds each. Each checked bag will be
          charged a fee, $10 EACH for the first 4 bags and $15 for each
          additional bag thereafter. Surf boards and other bulky items are
          charged at a fee dependent on the size and weight. Overweight bags are
          charged an additional fee starting at $15 each and can go up to $25
          each depending on the weight. Fees are accessed in increments. Smith's
          Ferry does not take responsibility for lost or damaged bags before,
          during or after the voyage.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>
            {" "}
            WE RESERVE THE RIGHT TO CANCEL SERVICE IN THE EVENT CIRCUMSTANCES
            THAT MAKE IT PHYSICALLY IMPOSSIBLE TO ACTIVATE THE VESSEL OCCURS. IF
            THIS OCCURS, WE WILL NOTIFY YOU AS SOON AS POSSIBLE BUT NOT LATER
            THAN THE SCHEDULED DEPARTURE DATE. IF THE FERRY IS CANCELLED, A FULL
            REFUND WILL BE MADE TO YOU WITHIN 14 DAYS AFTER CANCELLATION.
          </strong>
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "15px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          The rights and remedies made available under this contract are in
          addition to other rights or remedies under applicable law.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
        >
          <strong>INTERNATIONAL FERRIES:</strong> Carrier meets all BVI Shipping
          Registry and U.S Coast Guard regulations.
          <br /> I have read and agree to the terms and conditions of the
          Operator-Participant Contract.
        </div>
      </div>
      <br />
      <div
        className="row"
        style={{ width: "67%", marginLeft: "16%", fontSize: "17px" }}
      >
        <div
          className="col-sm-12"
          style={{
            textAlign: "justify",
            color: "rgb(2, 36, 68)",
            display: "none",
          }}
        >
          <button
            type="submit"
            className="form-control"
            style={{
              width: "100px",
              fontWeight: "bold",
              background: "rgb(2, 36, 68)",
              color: "white",
            }}
          >
            Strat Over
          </button>
        </div>
      </div>
    </>
  );
}
