import "../css/aboutStyle.css";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card } from "react-bootstrap";
export default function Contact() {
    return (
        <>
            <div className="App">
                <Navbar />
                <Container>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <div style={{ color: "rgb(2, 36, 68)", fontFamily: "Oswald, Sans-serif", fontSize: "55px", fontWeight: "700", textTransform: "uppercase" }}>CONTACTS</div>
                            </Card.Title>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-12" style={{ textAlign: "justify" }}>
                                            <h5 style={{ color: "rgb(2, 36, 68)", fontSize: "20px", fontWeight: "700", fontFamily: "Oswald, Sans-serif" }}>CONTACT US</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="1" style={{ width: "126px" }}>
                                            <hr />
                                        </div>
                                        <div className="10"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p style={{ color: "rgb(2, 36, 68)", textAlign: "justify", fontFamily: "Lato, Sans-serif", fontSize: "18px", fontWeight: "300" }}>You can contact us any way that is convenient for you. We are available via phone or email. You can also use a quick contact form below or visit our office personally.</p>
                                        </div>
                                    </div>


                                    {/* ---------------Email form-------------------*/}
                                    <form id="form1" onSubmit={{}}>
                                        <div className="row">
                                            <div className="col-sm-6 form-floating">
                                                <input className="form-control" type="text" name="txtFirstName" id="txtFirstName" placeholder="First Name" required />
                                                <label htmlFor="floatingInput">First Name</label>
                                            </div>
                                            <div className="col-sm-6 form-floating">
                                                <input className="form-control" type="text" name="txtLastName" id="txtLastName" placeholder="Last Name" required />
                                                <label htmlFor="floatingInput">Last Name</label>
                                            </div>

                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-sm-6 form-floating">
                                                <input className="form-control" type="text" name="txtEmail" id="txtEmail" placeholder="E-mail" required />
                                                <label htmlFor="floatingInput">E-mail</label>
                                            </div>
                                            <div className="col-sm-6 form-floating">
                                                <input className="form-control" type="text" name="txtPhone" id="txtPhone" placeholder="Phone" required />
                                                <label htmlFor="floatingInput">Phone</label>
                                            </div>

                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="floatingInput" style={{ marginRight: "92%" }}>Message</label>
                                                <textarea className="form-control" rows="5" id="txtmessage" name="txtmessage"></textarea>

                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button type="submit" className="btn btn-primary" style={{ background: "rgb(2, 36, 68)" }}>Send Message</button>

                                            </div>
                                        </div>
                                    </form>
                                    {/*-------------------------------------------*/}
                                </div>

                                <div className="col-sm-4">
                                    {/* -------------------Address--------------------------*/}
                                    <div className="row">
                                        <div
                                            className="col-sm-12"
                                            style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
                                        >
                                            <h5
                                                style={{
                                                    fontFamily: "Oswald, Sans-serif",
                                                    fontSize: "24px",
                                                    fontWeight: "700",
                                                    letterSpacing: "2.88px",
                                                }}
                                            >
                                                ADDRESS
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="1" style={{ width: "126px" }}>
                                            <hr />
                                        </div>
                                        <div className="10"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1" style={{ textAlign: "justify" }}>
                                            <img src="/location.png" alt=""></img>{" "}
                                        </div>
                                        <div
                                            className="col-sm-11"
                                            style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
                                        >
                                            Address: 3400 Veterans Drive,
                                            <br />
                                            St. Thomas, VI 00802
                                        </div>
                                    </div>
                                    {/* ------------END-----------------*/}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {/* -------------------Phone Number----------------------*/}
                                    <div className="row">
                                        <div
                                            className="col-sm-12"
                                            style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
                                        >
                                            <h5
                                                style={{
                                                    fontFamily: "Oswald, Sans-serif",
                                                    fontSize: "24px",
                                                    fontWeight: "700",
                                                    letterSpacing: "2.88px",
                                                }}
                                            >
                                                PHONES
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="1" style={{ width: "126px" }}>
                                            <hr />
                                        </div>
                                        <div className="10"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1" style={{ textAlign: "justify" }}>
                                            <img src="/icons8-phone-24.png" alt=""></img>{" "}
                                        </div>
                                        <div
                                            className="col-sm-11"
                                            style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
                                        >
                                            1-340-775-7292, 1-284-494-4454
                                        </div>
                                    </div>

                                    {/*------------------------End-------------------------------*/}
                                    <br />
                                    <br />  <br />
                                    <br />
                                    {/* ----------------------Email--------------------------*/}
                                    <div className="row">
                                        <div
                                            className="col-sm-12"
                                            style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
                                        >
                                            <h5
                                                style={{
                                                    fontFamily: "Oswald, Sans-serif",
                                                    fontSize: "24px",
                                                    fontWeight: "700",
                                                    letterSpacing: "2.88px",
                                                }}
                                            >
                                                E-MAIL
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="1" style={{ width: "126px" }}>
                                            <hr />
                                        </div>
                                        <div className="10"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1" style={{ textAlign: "justify" }}>
                                            <img src="/icons8-email-30.png" alt=""></img>{" "}
                                        </div>
                                        <div
                                            className="col-sm-11"
                                            style={{ textAlign: "justify", color: "rgb(2, 36, 68)" }}
                                        >
                                            Info@smithsferryservices.com
                                        </div>
                                    </div>




                                    {/* -------------------------END-------------------------------*/}
                                </div>
                            </div>
                            <br />
                            <div style={{ background: "rgb(2, 36, 68)", height: "200px", borderRadius: "14px", width: "100%", marginLeft: "0px" }}>
                                <div className="row">
                                    <div className="col-sm-8" style={{ paddingTop:"5%" }} >

                                    <div><h1 style={{ color:"white" }}>LOOKING FOR A GREAT TIME?</h1></div>

                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-sm-8" style={{ paddingTop:"1%" }}>
                                    <div><h5 style={{ color: "white" }}>Water Planet can give you an unforgettable summer holiday experience.</h5></div>
                                    </div>
                                    <div className="col-sm-2"><button type="submit" className="form-control" style={{ background: "rgb(2, 36, 68)", borderColor: "white", color: "white" }}>BOOK NOW</button></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    {/** Footer */}
                    <br />
                    <div className="row" style={{ backgroundColor: "rgb(2, 36, 68)" }}>
                        <div
                            className="col-sm-12"
                            style={{
                                color: "white",
                                fontFamily: "LufgaRegular",
                                fontSize: "15px",
                            }}
                        >
                            {" "}
                            � 2023 Copyright All Rights Reserved, Airmax System
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}
