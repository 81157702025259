import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/css/App.css";
import "../src/css/NavbarStyle.css";
import "../src/css/FlightSearchStyle.css";
//import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from "./Pages/About";
import Beforeyoufly from "./Pages/Beforeyoufly";
import Contact from "./Pages/Contact";
import OfacRequirements from "./Pages/OfacRequirements";
import CubaEntryRequiremenets from "./Pages/CubaEntryRequiremenets";
import BaggagePolicy from "./Pages/BaggagePolicy";
import AtTheAirportLink from "./Pages/AtTheAirportLink";
import FlightDetails from "./flights/Flight-details";
import PaymentProcess from "./flights/Payment-process";
import PaymentCheckOut from "./flights/PaymentCheckOut";
import ErrorPage from "./flights/ErrorPage";
import PyamentConfirmation from "./components/PaymentConfiramtion";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import translation_en from "./translations/en/language.json";
import translation_es from "./translations/es/language.json";

i18next.init({
  lng: "en",
  resources: {
    en: {
      translation: translation_en,
    },
    sp: {
      translation: translation_es,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    ),
  },
  {
    path: "/About",
    element: (
      <I18nextProvider i18n={i18next}>
        <About />
      </I18nextProvider>
    ),
  },
  {
    path: "/Beforeyoufly",
    element: (
      <I18nextProvider i18n={i18next}>
        <Beforeyoufly />
      </I18nextProvider>
    ),
  },
  {
    path: "/Contact",
    element: (
      <I18nextProvider i18n={i18next}>
        <Contact />
      </I18nextProvider>
    ),
  },
  {
    path: "/OfacRequirements",
    element: (
      <I18nextProvider i18n={i18next}>
        <OfacRequirements />
      </I18nextProvider>
    ),
  },
  {
    path: "/CubaEntryRequiremenets",
    element: (
      <I18nextProvider i18n={i18next}>
        {" "}
        <CubaEntryRequiremenets />
      </I18nextProvider>
    ),
  },
  {
    path: "/BaggagePolicy",
    element: (
      <I18nextProvider i18n={i18next}>
        <BaggagePolicy />
      </I18nextProvider>
    ),
  },
  {
    path: "/AtTheAirportLink",
    element: (
      <I18nextProvider i18n={i18next}>
        <AtTheAirportLink />
      </I18nextProvider>
    ),
  },
  {
    path: "/FlightDetails",
    element: (
      <I18nextProvider i18n={i18next}>
        <FlightDetails />
      </I18nextProvider>
    ),
  },
  {
    path: "/PaymentProcess",
    element: (
      <I18nextProvider i18n={i18next}>
        <PaymentProcess />
      </I18nextProvider>
    ),
  },
  {
    path: "/PaymentConfirmation",
    element: (
      <I18nextProvider i18n={i18next}>
        <PyamentConfirmation />
      </I18nextProvider>
    ),
  },
  {
    path: "/PaymentCheckOut",
    element: (
      <I18nextProvider i18n={i18next}>
        <PaymentCheckOut />
      </I18nextProvider>
    ),
  },
  {
    path: "/ErrorPage",
    element: (
      <I18nextProvider i18n={i18next}>
        <ErrorPage />
      </I18nextProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  /*  <App />   */
  <I18nextProvider i18n={i18next}>
    <RouterProvider router={router}></RouterProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
