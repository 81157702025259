import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router-dom";
import { all } from "axios";
export default function PyamentConfirmation() {
  var htmlstring = [];
  const loaction = useLocation();
  debugger;
  const [allLocation] = useState(loaction);
  htmlstring = allLocation.state.htmlStringRec;
  let emailId = allLocation.state.email;
  return (
    <>
      <Navbar />
      <Container>
        <Card>
          <Card.Title>Your payment was successful</Card.Title>
          <Card.Title>
            Confirmation has been sent to the email {emailId}
          </Card.Title>
        </Card>
        <br />
        <Card style={{ width: "62%", marginLeft: "17%" }}>
          <Card.Text>
            <div dangerouslySetInnerHTML={{ __html: htmlstring }}></div>
          </Card.Text>
        </Card>
      </Container>
    </>
  );
}
