import "../css/aboutStyle.css";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card } from "react-bootstrap";
export default function About() {
  return (
    <>
      <div className="App">
        <Navbar />
        <Container>
          <Card>
            <Card.Body>
              <Card.Title>
                <h5>ABOUT US</h5>
              </Card.Title>
              <Card.Text style={{ color: "gray" }}>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "20px",
                    lineHeight: "1.4em",
                  }}
                >
                  
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "20px",
                    lineHeight: "1.4em",
                  }}
                >
                 
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <div
            className="row"
            style={{ backgroundColor: "rgb(2, 36, 68)", marginTop: "24%" }}
          >
            <div
              className="col-sm-12"
              style={{
                color: "white",
                fontFamily: "LufgaRegular",
                fontSize: "15px",
              }}
            >
              {" "}
              © 2023 Copyright All Rights Reserved, Airmax System
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
