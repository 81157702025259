import "../css/aboutStyle.css";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card } from "react-bootstrap";
export default function AtTheAirportLink() {
  return (
    <>
      <div className="App">
        <Navbar />
        <Container className="ax-container">
          <Card>
            <Card.Body>
              <Card.Title>
                <h1>AT THE AIRPORT</h1>
              </Card.Title>
              <Card.Text style={{ color: "gray" }}>
                <h6>
                  Check-in begins 4 hours prior to, and closes exactly one hour
                  before, your scheduled flight departure. Please plan to arrive
                  at the airport with ample time for check-in.
                </h6>
                <div>
                  <strong> Miami Airport:</strong> Please defer to the screens
                  for the check-in counter for your specific flight
                </div>
                <div>
                  <strong>Tampa Airport:</strong> Cruise Quests counters are
                  located in the Red Terminal
                </div>
                <div>
                  <strong>Havana Airport:</strong> Cruise Quests departs from
                  and arrives at Terminal 2
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <div
            className="row"
            style={{ backgroundColor: "rgb(2, 36, 68)", marginTop: "31%" }}
          >
            <div
              className="col-sm-12"
              style={{
                color: "white",
                fontFamily: "LufgaRegular",
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              {" "}
              © 2023 Copyright All Rights Reserved, Airmax System
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
