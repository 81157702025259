import "../css/aboutStyle.css";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card } from "react-bootstrap";

export default function OfacRequirements() {
  return (
    <>
      <div className="App">
        <Navbar />
        <Container className="ax-container">
          <Card>
            <Card.Body>
              <Card.Title>
                <h1 style={{ marginRight: "64%" }}>OFAC REQUIREMENTS</h1>
              </Card.Title>

              <Card.Text>
                <div style={{ textAlign: "justify" }}>
                  Travel to Cuba for tourist activities remains prohibited by
                  statute. However, the Department of Treasury�s Office of
                  Foreign Assets Control (OFAC) has issued general licenses for
                  12 categories of travel. The 12 categories of authorized
                  travel to Cuba are:
                </div>
                <div>
                  <ol>
                    <li className="ax-list">Coffee</li>
                    <li className="ax-list">
                      Official business of the U.S. government, foreign
                      governments and certain intergovernmental organizations
                    </li>
                    <li className="ax-list">Journalistic activities</li>
                    <li className="ax-list">
                      Professional research or professional meetings
                    </li>
                    <li className="ax-list">Educational activities</li>
                    <li className="ax-list">Religious activities</li>
                    <li className="ax-list">
                      Public performances, clinics, workshops, athletic and
                      other competitions and exhibitions
                    </li>
                    <li className="ax-list">Support for the Cuban people</li>
                    <li className="ax-list">Humanitarian projects</li>
                    <li className="ax-list">
                      Activities of private foundations or research or
                      educational institutes
                    </li>
                    <li className="ax-list">
                      Exportation, importation, or transmission of information
                      or informational materials
                    </li>
                    <li className="ax-list">
                      Certain authorized export transactions
                    </li>
                  </ol>
                </div>
                <div style={{ textAlign: "justify" }}>
                  You can also travel under a specific OFAC-issued license, or
                  as a Cuban citizen returning home.
                </div>
                <div style={{ textAlign: "justify" }}>
                  Please note that you, as the traveler, will be required to
                  sign an affidavit stating your reason for travel, and are
                  personally responsible for adhering to OFAC requirements.
                  Cruise Quests takes no responsibility for non-compliance with
                  US laws and statutes.
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <div
            className="row"
            style={{ backgroundColor: "rgb(2, 36, 68)", marginTop: "6%" }}
          >
            <div
              className="col-sm-12"
              style={{
                color: "white",
                fontFamily: "LufgaRegular",
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              {" "}
              © 2023 Copyright All Rights Reserved, Airmax System
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
