import "../css/aboutStyle.css";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card } from "react-bootstrap";
export default function CubaEntryRequiremenets() {
  return (
    <>
      <div className="App">
        <Navbar />
        <Container className="ax-container">
          <Card>
            <Card.Body>
              <Card.Title>
                <h1 style={{ marginRight: "50%" }}>
                  WHAT ARE THE DOCUMENTS REQUIRED FOR CUBA TRAVEL?
                </h1>
              </Card.Title>
              <Card.Text>
                <h4>For All Travelers:</h4>
                <h4>Arrival Form:</h4>
                <h6 style={{ textAlign: "justify" }}>
                  Cuba requires that all travelers complete an online form and
                  show the QR code upon entry to Health and Customs officials.
                  Please either print or save to your phone (accessible without
                  an Internet connection). https://airmaxsystem.com Please note
                  that this form will not be accessible until 72 hours before
                  travel.
                </h6>

                <h2>Health Insurance:</h2>
                <h6>
                  The Cuban government requires all visitors to have health
                  insurance that covers the territory of Cuba; for U.S.
                  citizens, this means local Cuban health insurance. When
                  purchasing your Cruise Quests ticket to Cuba, Cuban health
                  insurance provided by ESICUBA and administered by Asistur is
                  automatically included in the cost of your fare.
                </h6>
                <h6>
                  Terms and conditions of the health insurance can be accessed
                  from the ESICUBA website (English version). Cuban health
                  insurance provided by ESICUBA is valid for only 30 calendar
                  days from the date of entry into Cuba. Non-Cuban residents
                  that are staying longer in Cuba, you must purchase additional
                  insurance to cover that period.
                </h6>
                <h6>
                  Although Cruise Quests is facilitating the purchase of this
                  required Cuban health insurance, Cruise Quests bears no
                  liability and all matters regarding the insurance policy's
                  terms and limits, and health care in Cuba, are solely between
                  the customer and ESICUBA.
                </h6>
                <h2>For U.S. and non-Cuban foreign residents:</h2>
                <h6>A passport that's valid for the entire length of stay</h6>
                <h6>
                  Tourist Card or Visa (unless you hold a passport from a
                  country that has a visa waiver agreement with Cuba)
                </h6>
                <h6>
                  A pink Cuban tourist card, which is sufficient for only
                  certain categories of OFAC-permitted travel, can be purchased
                  from our Cruise Quests office or check-in counter for $100.
                  Please note that green tourist cards issued in third countries
                  are not valid to enter Cuba from the U.S.
                </h6>
                <h6>
                  For those traveling with other visa types, including business,
                  journalism and other special visas, or citizens of countries
                  who require Embassy-issued tourist visas, must make the
                  necessary arrangements with Cuban Consulate in Washington, DC,
                  or in their home country, and must be obtained prior to
                  travel.
                </h6>
                <h2>For Cuban Nationals with U.S. residence:</h2>
                <h6>
                  The required documents depend on when you left Cuba: before or
                  after December 31, 1970.
                </h6>
                <h5>IF BEFORE:</h5>
                <h6>
                  A U.S. passport that's valid for the entire length of stay
                </h6>
                <h6>
                  HE-11 visa, which can be obtained through the Cuban Consulate
                  and takes four to six weeks to process
                </h6>
                <h5>IF AFTER</h5>
                <h6>
                  A Cuban passport with the appropriate Habilitaci�n
                  authorization and Prorroga sticker obtained through the Cuban
                  Consulate or from an immigration office in Cuba
                </h6>
                <h2>For Cuban Residents:</h2>
                <h6>
                  A Cuban passport with the appropriate Habilitaci�n
                  authorization and Prorroga sticker obtained through the Cuban
                  Consulate or from an immigration office in Cuba
                </h6>
                <h6>Residence of re-entry permit issued by Cuba</h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <div
            className="row"
            style={{ backgroundColor: "rgb(2, 36, 68)", marginTop: "8%" }}
          >
            <div
              className="col-sm-12"
              style={{
                color: "white",
                fontFamily: "LufgaRegular",
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              {" "}
              © 2023 Copyright All Rights Reserved, Airmax System
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
