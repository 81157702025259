import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "../css/FormControl.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
/*import Select from '@mui/material/Select';*/
import { useTranslation } from "react-i18next";
import departItem from "../AirportList/DepartAirport";
import destinItem from "../AirportList/DestinAirport";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/facebook";
import "react-social-icons/whatsapp";
import "react-social-icons/youtube";
import "react-social-icons/instagram";
import { useNavigate } from "react-router-dom";

export default function FormControls(props) {
  //For Date Picker..
  let isRoundTrip = true;
  let isOneWay = false;
  var date = new Date(); // add a day
  let depDate = date.setDate(date.getDate() + 1);
  let retDate = date.setDate(date.getDate() + 3);

  const [selectesDateDep, setSelectedDateDep] = useState(depDate);

  const [selectesDateRet, setSelectedDateRet] = useState(retDate);
  const [hideReturnDate, setRetunDateHide] = useState(true);
  // const [isAirplaneoading, setIsAirplaneoading] = useState(false);
  if (hideReturnDate === false) {
    isOneWay = true;
    isRoundTrip = hideReturnDate;
  }
  //End Date Picker..
  function departDate(date) {
    setSelectedDateDep(date);
    setSelectedDateRet(date);
  }

  //Language translation..
  const { t } = useTranslation("translation");
  //END

  // For Bind dropdownls
  const [dropdownDepart] = useState(departItem);
  const [selectvalOriginCity, setSelectvalOriginCity] = useState("");
  const [selectvalDestinationCity, setSelectvalDestinationCity] = useState("");
  const [selectvalOriginCityLabel, setSelectvalOriginCityLabel] = useState("");
  const [selectvalDestinationCityLabel, setSelectvalDestinationCityLabel] =
    useState("");

  useEffect(() => {
    setSelectvalOriginCity(departItem[0].value);
    setSelectvalDestinationCity(destinItem[0].value);
    setSelectvalOriginCityLabel(departItem[0].label);
    setSelectvalDestinationCityLabel(destinItem[0].label);
  }, []);
  //End Dropdown....

  //for popup... Number of pax selection...
  let totalCount = 1;
  let [countAdult, setCount] = useState(1);
  let [countInfant, setCountInfant] = useState(0);
  let [countChildrenUnder, setCountChildrenUnder] = useState(0);
  let [countChildren, setCountChildren] = useState(0);

  if (countAdult === 1) {
    totalCount = countAdult;
  } else {
    totalCount = countAdult;
  }
  function incrementAdult() {
    setCount(function (prevCount) {
      if (prevCount <= 10) {
        return (prevCount += 1);
      }
    });
  }
  function decrementAdult() {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  }
  // END adult..

  //CHILDREN 7-11 year count...

  if (countChildren > 0) {
    totalCount += countChildren;
  }
  function incrementChildren() {
    setCountChildren(function (prevCount) {
      if (prevCount <= 10) {
        return (prevCount += 1);
      }
    });
  }
  function decrementChildren() {
    setCountChildren(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  }
  //END CHILDREN 7-11 year count..

  //CHILDREN Under 7 year count...

  if (countChildrenUnder > 0) {
    totalCount += countChildrenUnder;
  }
  function incrementChildrenUnder() {
    setCountChildrenUnder(function (prevCount) {
      if (prevCount <= 10) {
        return (prevCount += 1);
      }
    });
  }
  function decrementChildrenUnder() {
    setCountChildrenUnder(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  }
  //END CHILDREN 7-11 year count..

  //INFANTS (under 2 years old)

  if (countInfant > 0) {
    totalCount += countInfant;
  }
  function incrementInfant() {
    setCountInfant(function (prevCount) {
      if (prevCount <= 10) {
        return (prevCount += 1);
      }
    });
  }
  function decrementInfant() {
    setCountInfant(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  }
  //Flight search button
  const navigate = useNavigate();
  const handleSubmit = () => {
    //alert(selectesDate.toLocaleDateString());
    navigate("/FlightDetails", {
      replace: true,
      state: {
        countAdult,
        countInfant,
        countChildren,
        countChildrenUnder,
        totalCount,
        selectesDateDep,
        selectesDateRet,
        selectvalOriginCity,
        selectvalDestinationCity,
        isRoundTrip,
        isOneWay,
        selectvalOriginCityLabel,
        selectvalDestinationCityLabel,
      },
    });
  };
  //End
  const onSubmit = (e) => {
    e.preventDefault();
  };
  //END INFANTS (under 2 years old)++
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <label
            className={
              !hideReturnDate ? "btn btn-secondary" : "btn btn-secondary active"
            }
          >
            <input
              type="radio"
              name="roundtrip"
              id="btnRoundTrip"
              autoComplete="off"
              onClick={() => setRetunDateHide(true)}
            />{" "}
            {t("R")}
          </label>
          <label
            className={
              hideReturnDate ? "btn btn-secondary" : "btn btn-secondary active"
            }
          >
            <input
              type="radio"
              name="oneway"
              id="btnOneWay"
              autoComplete="off"
              onClick={() => setRetunDateHide(false)}
            />{" "}
            {t("O")}
          </label>
        </div>
        {/** DropDown controls */}
        <div className="row" style={{ padding: "1%" }}>
          <div className="col-md-1 col-sm-1"></div>
          <div className="col-md-5 form-floating">
            <select
              onChange={(e) => setSelectvalOriginCity(e.target.value)}
              value={selectvalOriginCity}
              className="form-select"
              id="ddlDepartCity"
              aria-label="Floating label select example"
            >
              {dropdownDepart.map((item) => (
                <option
                  key={item.value}
                  value={item.value}
                  style={{
                    color: selectvalOriginCity === item.label ? "Red" : "black",
                  }}
                >
                  {item.label}
                </option>
              ))}
            </select>
            <label htmlFor="floatingSelect" style={{ marginLeft: "14px" }}>
              {t("DeptCity")}
            </label>
          </div>
          <div className="col-md-5 form-floating">
            <select
              onChange={(e) => setSelectvalDestinationCity(e.target.value)}
              value={selectvalDestinationCity}
              className="form-select"
              id="ddlDestinationCity"
              aria-label="Floating label select example"
            >
              {destinItem.map((item) => (
                <option
                  key={item.value}
                  value={item.value}
                  style={{
                    color:
                      selectvalDestinationCity === item.label ? "Red" : "black",
                  }}
                >
                  {item.label}
                </option>
              ))}
            </select>
            <label
              htmlFor="floatingSelect"
              style={{ marginLeft: "14px", backgroundColor: "none" }}
            >
              {t("ArrivingCity")}
            </label>
          </div>
          <div className="col-md-1 col-sm-1">
            <Popup
              style={{ width: "200px !important", display: "block" }}
              trigger={
                <button
                  className="ax-btn-social"
                  style={{ background: "rgb(2, 36, 68)" }}
                >
                  {" "}
                  {/*<i className="fa fa-phone" style={{ color: "white" }}></i>*/}
                  <img
                    src="./socialIcon.png"
                    alt=""
                    width="50"
                    height="40px"
                  ></img>
                </button>
              }
              position="top"
            >
              <div style={{ width: "100%" }}>
                <div className="row socialIcon">
                  <div className="col-lg-12">
                    <SocialIcon url="www.facebook.com" /> FACEBOOK
                  </div>
                </div>
                <div className="row socialIcon">
                  <div className="col-lg-12">
                    <SocialIcon url="www.whatsapp.com" /> WHATSAPP
                  </div>
                </div>
                <div className="row socialIcon">
                  <div className="col-lg-12">
                    <SocialIcon url="www.youtube.com" />
                    YOUTUBE
                  </div>
                </div>
                <div className="row socialIcon">
                  <div className="col-lg-12">
                    <SocialIcon url="www.instagram.com" /> INSTAGRAM
                  </div>
                </div>
              </div>
            </Popup>
          </div>
        </div>
        {/** END DropDown controls */}

        {/** Start Date controls */}
        <div className="row gy-2 gx-3" style={{ padding: "1%" }}>
          <div className="col-lg-1"></div>
          <div
            className="col-lg-3 form-floating d-flex"
            style={{ textAlign: "center" }}
          >
            <DatePicker
              selected={selectesDateDep}
              onChange={departDate}
              placeholder={"DEPARTURES"}
              dateFormat={"MM-dd-yyyy"}
              className="ax-custom-input-Depart form-control"
              dayClassName={() => "ax-datepicker-day-class"}
              popperClassName="ax-datepicker-class"
              todayButton="TODAY"
            />
            <label htmlFor="datepickerDeparture" className="ax-depart-for">
              {t("DepartDate")}
            </label>
          </div>
          <div className="col-lg-2 form-floating d-flex">
            {hideReturnDate && (
              <DatePicker
                selected={selectesDateRet}
                onChange={(date) => setSelectedDateRet(date)}
                dateFormat={"MM-dd-yyyy"}
                className="ax-custom-input-Return ax-custom-input:focus-visible form-control ax-Dest-DatePicker"
                dayClassName={() => "ax-datepicker-day-class"}
                popperClassName="ax-datepicker-class"
                todayButton="TODAY"
              />
            )}
            {hideReturnDate && (
              <label htmlFor="datepickerReturn" className="ax-return-for">
                {t("ReturnDate")}
              </label>
            )}
          </div>
          {/* <div className="col-lg-1"> </div>*/}
          {/**start popup */}
          <div className="col-lg-3">
            <Popup
              contentStyle={{ width: "448px" }}
              style={{ backgroundColor: "red !important" }}
              trigger={
                <button
                  style={{
                    height: "50px",
                    background: "rgb(2, 36, 68)",
                    color: "white",
                    border: "none",
                  }}
                  className="form-control"
                >
                  <span
                    style={{
                      flexDirection: "row",
                      boxSizing: "border-box",
                      display: "flex",
                      placeContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        marginright: "2em",
                        fontFamily: "LufgaRegular",
                        paddingLeft: "0px",
                      }}
                    >
                      {t("PASSENGERS")}
                    </span>
                    <span
                      style={{
                        flexDirection: "row",
                        boxSizing: "border-box",
                        display: "flex",
                        placeContent: "center",
                        alignItems: "center",
                        paddingRight: "30px",
                      }}
                    >
                      <i
                        className="fa fa-user"
                        aria-hidden="true"
                        style={{
                          marginRight: "0.5rem",
                          userSelect: "none",
                          backgroundrepeat: "no-repeat",
                          display: "inline-block",
                          fill: "currentColor",
                          height: "17px",
                          width: "24px",
                          overflow: "hidden",
                        }}
                      ></i>
                      <strong style={{ color: "white" }}>{totalCount}</strong>
                    </span>
                  </span>
                </button>
              }
              position={"top center"}
            >
              <div style={{ width: "100%" }}>
                {/**Adult.. */}
                <div className="row">
                  <div className="col-lg-6 ax-popup-header">{t("adult")}</div>
                  <div className="col-lg-6" style={{ textAlign: "center" }}>
                    <button
                      id="btnAdultMinus"
                      className="btnMinuesAdult"
                      style={{
                        border: "none",
                        borderRadius: "9px",
                        background: "rgba(0, 0, 0, .12)",
                      }}
                      onClick={decrementAdult}
                      disabled={countAdult === 1}
                    >
                      <i
                        className="fa fa-minus-circle adult-minues"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <strong id="btnAdultCount" style={{ padding: "0px 30px" }}>
                      {countAdult}
                    </strong>
                    <button
                      id="btnAdultPluse"
                      style={{
                        background: "rgb(255,23,68)",
                        border: "none",
                        borderRadius: "9px",
                      }}
                      onClick={incrementAdult}
                      disabled={countAdult === 10}
                    >
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      ></i>
                    </button>
                  </div>
                </div>
                {/**CHILDREN (7 – 11 years).. */}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-lg-6 ax-popup-header">
                    {t("children")}
                  </div>
                  <div className="col-lg-6" style={{ textAlign: "center" }}>
                    <button
                      id="btnChildrenUpSevenMinus"
                      style={{
                        border: "none",
                        borderRadius: "9px",
                        background: "rgba(0, 0, 0, .12)",
                      }}
                      onClick={decrementChildren}
                      disabled={countChildren === 0}
                    >
                      <i className="fa fa-minus-circle" aria-hidden="true"></i>
                    </button>
                    <strong
                      id="btnChildrenUpSevenCount"
                      style={{ padding: "0px 30px" }}
                    >
                      {countChildren}
                    </strong>
                    <button
                      id="btnChildrenUpSevenPluse"
                      style={{
                        background: "rgb(255,23,68)",
                        border: "none",
                        borderRadius: "9px",
                      }}
                      onClick={incrementChildren}
                      disabled={countChildren === 10}
                    >
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      ></i>
                    </button>
                  </div>
                </div>
                {/**CHILDREN (under 7 years old).. */}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-lg-6 ax-popup-header">
                    {" "}
                    {t("childrenUnder")}
                  </div>
                  <div className="col-lg-6" style={{ textAlign: "center" }}>
                    <button
                      id="btnChildrenUnderSevenMinus"
                      style={{
                        border: "none",
                        borderRadius: "9px",
                        background: "rgba(0, 0, 0, .12)",
                      }}
                      onClick={decrementChildrenUnder}
                      disabled={countChildrenUnder === 0}
                    >
                      <i className="fa fa-minus-circle" aria-hidden="true"></i>
                    </button>
                    <strong
                      id="btnChildrenUnderSevenCount"
                      style={{ padding: "0px 30px" }}
                    >
                      {countChildrenUnder}
                    </strong>
                    <button
                      id="btnChildrenUnderSevenPluse"
                      style={{
                        background: "rgb(255,23,68)",
                        border: "none",
                        borderRadius: "9px",
                      }}
                      onClick={incrementChildrenUnder}
                      disabled={countChildrenUnder === countAdult}
                    >
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      ></i>
                    </button>
                  </div>
                  <div></div>
                </div>
                {/**INFANTS (under 2 years old).. */}
                <div
                  className="row ax-popup-header"
                  style={{ marginTop: "10px" }}
                >
                  <div className="col-lg-6">{t("infant")}</div>
                  <div className="col-lg-6" style={{ textAlign: "center" }}>
                    <button
                      id="btnInfantesMinus"
                      style={{
                        border: "none",
                        borderRadius: "9px",
                        background: "rgba(0, 0, 0, .12)",
                      }}
                      onClick={decrementInfant}
                      disabled={countInfant === 0}
                    >
                      <i className="fa fa-minus-circle" aria-hidden="true"></i>
                    </button>
                    <strong
                      id="btnInfantesCount"
                      style={{ padding: "0px 30px" }}
                    >
                      {countInfant}
                    </strong>
                    <button
                      id="btnInfantesPluse"
                      style={{
                        background: "rgb(255,23,68)",
                        border: "none",
                        borderRadius: "9px",
                      }}
                      onClick={incrementInfant}
                      disabled={countInfant === countAdult}
                    >
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      ></i>
                    </button>
                  </div>
                </div>
                {/**End */}
              </div>
            </Popup>
          </div>

          {/**End popup */}

          <div className="col-lg-2 ">
            <button
              type="submit"
              id="btnSearch"
              onClick={handleSubmit}
              className="btn btn-dark form-control"
              //className="mx-button ax-mx-button.mat-unthemed ax-mx-button form-control btn-flight-search"
              style={{
                background: "#022444",
                color: "white",
                height: "50px",
                fontFamily: "LufgaRegular",
                fontSize: "15px",
              }}
            >
              <span className="mx-button__label">{t("Search Trip")}</span>
            </button>
          </div>
        </div>
        {/** End Date controls */}
      </form>
    </>
  );
}
