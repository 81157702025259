/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import DoneIcon from "../Image/done.png";
import "../css/PaymentProcess.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import yearListS from "../flights/yearItem";
import MainURL from "../AirportList/MainUrl";

export default function PaymentProcess() {
  //Set location for get number of pax selected
  const loaction = useLocation();
  const [alllocation] = useState(loaction);
  const [liveUrl] = useState(MainURL[0]);
  // alert(alllocation.state.adultCount);
  //const PatternEmail ='[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*";
  const Patterntext = /^[A-Za-z]+$/;
  let one = "1";
  let two = "2";
  let three = "3";
  var bookPax = "";
  //Get all infoamtion related to number of pax selecte during search....

  let isOW = alllocation.state.OneWay;
  let adult = alllocation.state.adultCount;
  let infant = alllocation.state.infantCount;
  let Children = alllocation.state.Children;
  let ChildrenUnder = alllocation.state.ChildrenUnderCount;
  let originA = alllocation.state.origin;
  let destinationD = alllocation.state.destination;
  let flightNumber = alllocation.state.flightNumber;
  let flightId = alllocation.state.flightID;
  let timeArr = alllocation.state.TimeArr;
  let timeDep = alllocation.state.TimeDep;
  let paxFareAmount = alllocation.state.fareListAmount;
  let originLabel = alllocation.state.originLabel;
  let destinationLabel = alllocation.state.destinationLabel;
  //------------------DEPT Flight---------------//
  const optMonth = { month: "long", day: "numeric" };
  const optWeekdays = { weekday: "long" };
  let ferryDate = alllocation.state.FerryDate;
  const newDate = new Date(ferryDate);
  const weekDay = newDate
    .toLocaleDateString("en-US", optWeekdays)
    .toUpperCase();
  const monthDay = newDate.toLocaleDateString("en-US", optMonth).toUpperCase();
  //--------------------End-------------------//

  //---------------------------Return Flight-------------
  let retFlightId = alllocation.state.retflightID;
  let retFlightNumber = alllocation.state.retFlightNumber;
  let retTimeArr = alllocation.state.retTimeArr;
  let retTimeDep = alllocation.state.retTimeDep;
  let retferryDate = alllocation.state.retFerryDate;
  const newDateRet = new Date(retferryDate);
  const weekDayRet = newDateRet
    .toLocaleDateString("en-US", optWeekdays)
    .toUpperCase();
  const monthDayRet = newDateRet
    .toLocaleDateString("en-US", optMonth)
    .toUpperCase();
  //-----------------------------END--------------------

  let selectedFirstClass = alllocation.state.firstClasses;
  let isClassOW = false;
  let isClassRT = false;
  // set Firstclass
  if (isOW === true && selectedFirstClass === true) {
    isClassOW = true;
  }
  if (isOW !== true && selectedFirstClass === true) {
    isClassRT = true;
  }

  let ferryName = alllocation.state.ferryName;
  //For time calculation- Depart Flight.....
  var startDep = timeDep.split(" ")[0];
  var endArr = timeArr.split(" ")[0];
  var start = startDep.split(":");
  var end = endArr.split(":");
  var startDate = new Date(0, 0, 0, start[0], start[1], 0);
  var endDate = new Date(0, 0, 0, end[0], end[1], 0);
  var diff = endDate.getTime() - startDate.getTime();
  var hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  var minutes = Math.floor(diff / 1000 / 60);
  var totalTime =
    (hours < 9 ? "0" : "") +
    hours +
    " hr " +
    (minutes < 9 ? "0" : "") +
    minutes +
    " min";

  //For Time calculation -Return Flight...
  var retStartDep = retTimeDep.split(" ")[0];
  var retEndArr = retTimeArr.split(" ")[0];
  var startRet = retStartDep.split(":");
  var endRet = retEndArr.split(":");
  var startDateRet = new Date(0, 0, 0, startRet[0], startRet[1], 0);
  var endDateRet = new Date(0, 0, 0, endRet[0], endRet[1], 0);
  var diffRet = endDateRet.getTime() - startDateRet.getTime();
  var hoursRet = Math.floor(diffRet / 1000 / 60 / 60);
  diff -= hoursRet * 1000 * 60 * 60;
  var minutesRet = Math.floor(diff / 1000 / 60);
  var totalTimeRet =
    (hours < 9 ? "0" : "") +
    hours +
    " hr " +
    (minutesRet < 9 ? "0" : "") +
    minutesRet +
    " min";

  //END
  //alert(totalTime);
  //End here...
  const [horizentalLine, sethorizentalLine] = useState(true);
  //For Model...
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const [dataCountry, setDataCountry] = useState([]);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  // Payment section/card validation...
  const [txtCardNumber, settxtCardNumber] = useState("");
  const [bookingPNR, setBookingPNR] = useState("");
  const [bookingTotalAmount, setBookingTotalAmount] = useState("");
  let paxName = "";
  let totalPrice = 0;
  const [txtCardCode, settxtCardCode] = useState("");
  const [txtFirstName, settxtFirstName] = useState("");
  const [txtZip, settxtZip] = useState("");

  const [yearList] = useState(yearListS);

  const [yearListItem, setYearListItem] = useState("");
  useEffect(() => {
    setYearListItem([yearListS[0]]);
  }, []);

  const handleCardNumber = (event) => {
    settxtCardNumber(event.target.value);
  };
  //const handleMonth = (event) => {
  //  setdllMonth(event.target.value);
  //  alert(ddlMonth);
  //};
  //const handleYear = (event) => {
  //  setddlYear(event.targat.value);
  //};
  useEffect(() => {
    LoadInfo();
  }, []);
  const handleCardCode = (event) => {
    settxtCardCode(event.target.value);
  };
  const handleFirstName = (event) => {
    settxtFirstName(event.target.value);
  };

  const handleZipCode = (event) => {
    settxtZip(event.target.value);
  };

  //Card end validation
  const navigate = useNavigate();
  // async function handleShow(event) {
  //   event.preventDefault();
  //   navigate("/PaymentCheckOut");
  // }
  async function handleShow(event) {
    var passangerList = [];
    let DepartFerriesList = [];
    let ReturnFerriesList = [];
    event.preventDefault();
    for (let i = 0; i < totalPax; i++) {
      var FirstName = document.getElementById("txtFirstname_" + i).value;
      var MiddleName = document.getElementById("txtMiddleName_" + i).value;
      var LastName = document.getElementById("txtLastName_" + i).value;
      var Gender = document.getElementById("dllGeneder_" + i).value;
      var DOB = document.getElementById("txtBirthDate_" + i).value;
      var DocumentId = document.getElementById("txtDocumentnumber_" + i).value;
      var IssueCountry = document.getElementById("txtIssuedCountry_" + i).value;
      var ExpriationDate = document.getElementById(
        "txtExpirationDate_" + i
      ).value;
      var Phone = document.getElementById("txtPhone_" + i).value;
      var Email = document.getElementById("txtEmail_" + i).value;

      //Set Up Validation.......
      if (FirstName.trim() === "" || !Patterntext.test(FirstName)) {
        alert("Invalid first name!");
        return;
      } else if (LastName.trim() === "" || !Patterntext.test(LastName)) {
        alert("Invalid last name!");
        return;
      } else if (Gender.trim() === "") {
        alert("Please select gender!");
        return;
      } else if (DOB.trim() === "") {
        alert("Please enter valid dob!");
        return;
      } else if (DocumentId.trim() === "") {
        alert("Please enter document number!");
        return;
      } else if (IssueCountry.trim() === "") {
        alert("Please select country");
        return;
      } else if (ExpriationDate.trim() === "") {
        alert("Please select valid expriation date !");
        return;
      } else if (Phone.trim() === "") {
        alert("Please enter valid phone number !");
        return;
      } else if (Email.trim() === "") {
        alert("Please enter valid email !");
        return;
      }

      var dateExp = new Date(moment(ExpriationDate).format("l"));
      var currDate = new Date(moment().format("l"));
      if (currDate > dateExp) {
        alert("Please enter correct Document Expiry Date");
        return;
      }

      //End

      let DepartFerriesitem = flightId;
      let ReturnFerriesitem = retFlightId;
      DepartFerriesList.push(DepartFerriesitem);
      ReturnFerriesList.push(ReturnFerriesitem);
      passangerList.push({
        firstName: FirstName,
        lastName: LastName,
        middleName: MiddleName,
        mothersMaidenName: "string",
        dateOfBirth: DOB,
        gender: Gender,
        email: Email,
        phone: Phone,
        weight: 5,
        residenceCountry: IssueCountry,
        documentNumber: DocumentId,
        documentExpiry: ExpriationDate,
        documentType: "No",
        documentCountry: IssueCountry,
        cubaDocumentType: "NO",
        cubaDocumentNumber: "NO",
        cubaDocumentExpiry: "2023-12-23",
        cubaDocumentCountry: "NO",
        ofacCode: "45H",
        addressStreet: "NO",
        addressCity: "NO",
        addressState: "NO",
        addressZipCode: "NO",
        cubaAddressStreet: "NO",
        cubaAddressCity: "NO",
        cubaAddressProvince: "no",
        cubaAddressZipCode: "NO",
        cubaPhone: "no",
      });
    } //END loop
    let Id = 0;
    if (ferryName === "Smith's Ferry") {
      Id = 1;
    } else {
      Id = 2;
    }
    var raw = JSON.stringify({
      DepartFerries: DepartFerriesList,
      ReturnFerries: ReturnFerriesList,
      Passengers: passangerList,
      IsDepartFirstClass: isClassOW,
      IsReturnFirstClass: isClassRT,
      AddInsurance: false,
      company: Id,
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setIsLoadingPayment(true);
    fetch(liveUrl.liveUrl + "Book/CreateCQBooking", requestOptions).then(
      (response) => {
        response.json().then((result) => {
          if (result.data[0].bookingSuccessful === true) {
            window.alert("Passenger added successfully");

            // let bookingPNR = [];
            let fare = result.data[0].totalPrice;
            // for (let i = 0; i < result.data.length; i++) {
            //   bookingPNR.push(result.data[i].confNum);
            //   fare = result.data[0].totalPrice;
            // }
            let email = passangerList[0].email;
            let bookingPNR = result.data[0].confNum;
            let name = result.data[0].name;
            let depart = result.data[0].departFerry;
            let retFerry = result.data[0].returnFerry;
            let company = result.data[0].company;
            setTimeout(() => {
              isLoadingPayment(false);
            }, 2000);
            navigate("/PaymentCheckOut", {
              replace: true,
              state: {
                name,
                fare,
                bookingPNR,
                depart,
                retFerry,
                company,
                ferryDate,
                flightId,
                retFlightId,
                paxFareAmount,
                Id,
                email,
              },
            });
          } else {
            setTimeout(() => {
              setIsLoadingPayment(false);
            }, 2000);
            alert(result.data);
          }
        });
      }
    );
  }

  const OnPaymentCheckOut = () => {
    try {
      // setIsLoadingPayment(true);
      navigate("/PaymentConfirmation");
      //setTimeout(() => {
      //    setIsLoadingPayment(false);
      //}, 3500);
      if (txtCardNumber.trim().length === 0) {
        alert("Please enter valid card number!");
        return;
      }
      if (ddlMonth.trim().length === 0) {
        alert("Invalid expiration month !");
        return;
      }
      if (ddlYear.trim().length === 0) {
        alert("Invalid expiration year !");
      }
      if (txtCardCode.trim().length === 0) {
        alert("Invalid card code !");
        return;
      }
      if (txtFirstName.trim().length === 0) {
        alert("Please enter first name !");
        return;
      }
      if (txtLastName.trim().length === 0) {
        alert("Please enter last name !");
        return;
      }
      if (txtZip.trim().length === 0) {
        alert("Please enter zip code!");
        return;
      }
    } catch (error) {
      alert(error);
    }
  };
  //End Model...
  function LoadInfo() {
    debugger;
    var today = new Date().toISOString().split("T")[0];
    for (let i = 0; i < totalPax; i++) {
      document
        .getElementsByName("txtExpirationDate_" + i)[0]
        .setAttribute("min", today);
      debugger;
      document
        .getElementsByName("txtBirthDate_" + i)[0]
        .setAttribute("max", today);
    }
  }
  const ClickDoneIcon = () => {
    sethorizentalLine(true);
  };

  let NumPax = 1;
  let totalPax = alllocation.state.TotalCountPax;

  var htmlString = [];
  //Html generate....... Based on total pax count....
  for (let i = 0; i < totalPax; i++) {
    if (adult > 0) {
      adult--;
      bookPax = "Adult";
    } else if (infant > 0) {
      infant--;
      bookPax = "Infant";
    } else if (Children > 0) {
      Children--;
      bookPax = "Children (7 – 11)";
    } else if (ChildrenUnder > 0) {
      ChildrenUnder--;
      bookPax = "Children (Under 7)";
    }

    //Start Passenger Information.....
    htmlString +=
      "<div><h5 style='font-weight:bold;color:rgb(2, 36, 68);padding-right:80%;'> Passenger-" +
      NumPax +
      " " +
      bookPax +
      "</i> </h3></div>";
    htmlString += "<hr/>";
    htmlString += "<div class='row' style='padding:10px;'>";
    htmlString += "<div class='col-md-3 form-floating' >";
    htmlString +=
      "<select class='form-select' style='border-radius:0px' required aria-label='select example' name='dllGeneder_" +
      i +
      "' id='dllGeneder_" +
      i +
      "'><option value=''>--Select--</option><option value='M'>Male</option><option value='F'>Female</option></select> <label for='txtGender' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Gender *</label>";
    htmlString += "</div > ";
    htmlString +=
      "<div class='col-md-3 form-floating'><input type='text' style='border-radius:0px' class='form-control' name='txtFirstname_" +
      i +
      "' id='txtFirstname_" +
      i +
      "' required  /><label for='floatingInputFirstName' style='font-weight:bold;color:rgb(2, 36, 68);'>First Name</label></div>";
    htmlString +=
      "<div class='col-md-3 form-floating'><input type='text' style='border-radius:0px' class='form-control' name='txtMiddleName_" +
      i +
      "' id='txtMiddleName_" +
      i +
      "' /><label for='txtMiddleName' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Middle Name</label></div>";
    htmlString +=
      "<div class='col-md-3 form-floating'><input type='text' class='form-control' style='border-radius:0px' name='txtLastName_" +
      i +
      "'  id='txtLastName_" +
      i +
      "' required /><label for='txtLastName' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Last Name</label></div>";

    htmlString += "</div>";
    htmlString += "<div class='row form-floating' style='padding:10px;'>";

    htmlString += "<div class='col-sm-3 form-floating'>";
    htmlString +=
      "<input type='date' class='form-control' style='border-radius:0px' required name='txtBirthDate_" +
      i +
      "' id='txtBirthDate_" +
      i +
      "'/><label for='txtDOB' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Birth Date</label></div>";
    htmlString +=
      "<div class='col-md-3 form-floating'><select class='form-select' style='border-radius:0px' required aria-label='Default select example' name='txtIssuedCountry_" +
      i +
      "' id='txtIssuedCountry_" +
      i +
      "'><option value=''>--Select--</option><option value='USA'>United States</option><option value='UK'>United Kingdom</option><option value='VI'>Virgin Islands, U.S.</option><option value='227'>Virgin Islands, British</option><option value='3'>Afghanistan</option><option value='4'>Albania</option><option value='5'>Algeria</option><option value='6'>American Samoa</option><option value='7'>Andorra</option><option value='8'>Angola</option><option value='9'>Anguilla</option><option value='10'>Antigua and Barbuda</option><option value='11'>Argentina</option><option value='12'>Armenia</option><option value='13'>Aruba</option><option value='14'>Australia</option><option value='15'>Austria</option><option value='16'>Azerbaijan</option><option value='2'>Bahamas</option><option value='17'>Bahrain</option><option value='18'>Bangladesh</option><option value='19'>Barbados</option><option value='20'>Belarus</option><option value='21'>Belgium</option><option value='22'>Belize</option><option value='23'>Benin</option><option value='24'>Bermuda</option><option value='25'>Bhutan</option>";

    //var ddlCountryItem =
    //  dataCountry &&
    //  dataCountry.map((country) => {
    //    return (
    //      "<option value=" +
    //      country.PKID_Country +
    //      ">" +
    //      country.Name +
    //      "</option>"
    //    );
    //  });
    //htmlString += ddlCountryItem;
    htmlString +=
      "</select > <label for='txtIssuedCountry' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Citizenship</label> </div > ";
    htmlString +=
      "<div class='col-md-3 form-floating'><input type='text' class='form-control is-invalid' required style='border-radius:0px' name='txtDocumentnumber_" +
      i +
      "' id='txtDocumentnumber_" +
      i +
      "'/><label for='txtDocumentnumber' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Passport # or <strong style='font-size:10px'>Alien Resident card</strong></label> </div>";
    htmlString += "<div class='col-sm-3 form-floating'>";
    htmlString +=
      "<input type='date' class='form-control' required name='txtExpirationDate_" +
      i +
      "' id='txtExpirationDate_" +
      i +
      "'/><label for='txtExpirationDate' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Expiration Date</label></div>";

    htmlString += "</div>";

    htmlString += "<div class='row' style='padding:10px;'>";

    htmlString += "<div class='col-md-3 form-floating' style='display:none'> ";
    htmlString +=
      "<select class='form-select' aria-label='select example' name='dllReminder_" +
      i +
      "' required id='dllReminder_" +
      i +
      "'><option value=''>No Reminder</option>< option value ='1'> 1 days before ferry</option ><option value='2'>2 days before ferry</option><option value='3'>3 days before ferry</option><option value='4'>4 days before ferry</option><option value='5'>5 days before ferry</option><option value='6'>6 days before ferry</option><option value='7'>7 days before ferry</option>";
    //var ddlStateItem =
    //  dataState &&
    //  dataState.map((state) => {
    //    return (
    //      "<option value=" +
    //      state.PKID_State +
    //      ">" +
    //      state.StateName +
    //      "</option>"
    //    );
    //  });
    //htmlString += ddlStateItem;
    htmlString +=
      "</select><label for='txtProvince' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Email Reminder</label></div>";
    htmlString += "<div class='col-md-3 form-floating'>";
    htmlString +=
      "<input type='email' class='form-control' name='txtEmail_" +
      i +
      "' id='txtEmail_" +
      i +
      "'/> <label for='txtMunicipality' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Email</label></div>";
    htmlString += "<div class='col-md-3 form-floating'> ";
    htmlString +=
      "<input type='number' class='form-control' maxlength='12' name='txtPhone_" +
      i +
      "' id='txtPhone_" +
      i +
      "' required/><label for='txtStreet' class='form-label' style='font-weight:bold;color:rgb(2, 36, 68);'>Phone</label></div>";

    htmlString += "</div>";

    NumPax++;
  }
  function LoadInfo() {
    debugger;
    var today = new Date().toISOString().split("T")[0];
    for (let i = 0; i < totalPax; i++) {
      document
        .getElementsByName("txtExpirationDate_" + i)[0]
        .setAttribute("min", today);
      debugger;
      document
        .getElementsByName("txtBirthDate_" + i)[0]
        .setAttribute("max", today);
    }
  }
  return (
    <>
      <Navbar />
      <Container style={{ maxWidth: "96%" }}>
        <Card>
          <div className="row">
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: "Red",
                    borderRadius: "20px",
                    width: "30px",
                    fontSize: "20px",
                    color: "white",
                  }}
                >
                  {!horizentalLine ? one : <img src={DoneIcon} alt="" />}
                </div>
                <div
                  style={{ flex: 1, height: "1px", backgroundColor: "black" }}
                />
                <div>
                  <div
                    style={{
                      background: !horizentalLine ? "gray" : "red",
                      width: "30px",
                      textAlign: "center",
                      borderRadius: "20px",
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={ClickDoneIcon}
                  >
                    {two}
                  </div>
                </div>
                <div
                  style={{ flex: 1, height: "1px", backgroundColor: "black" }}
                />
                <div
                  style={{
                    width: "30px",
                    textAlign: "center",
                    background: "gray",
                    borderRadius: "20px",
                    fontSize: "20px",
                  }}
                >
                  {three}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>Price</div>
                <div style={{ flex: 1 }} />
                <div>
                  <div>Payment</div>
                </div>
                <div style={{ flex: 1 }} />
                <div>Confirmation</div>
              </div>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col-lg-12">
              <Card
                style={{
                  borderRadius: "5px",
                  background: "rgb(2, 36, 68)",
                  color: "white",
                }}
              >
                <p>
                  {/*<img*/}
                  {/*  src={FlightIcon}*/}
                  {/*  alt=""*/}
                  {/*  style={{ color: "white", width: "24px", height: "24px" }}*/}
                  {/*/>{" "}*/}
                  {/*Flight*/}
                </p>
              </Card>
            </div>
          </div>
          <Card
            style={{
              borderRadius: "5px",
            }}
          >
            <div className="row">
              <div className="col-sm-9">
                <br />
                <div className="row">
                  <div className="col-sm-2">
                    {/* <img src={FlightImage} className="axIcon" alt=""></img>*/}
                    <span className="fecha-salida">{weekDay}</span>
                    <br />
                    <span className="fecha-salida">{monthDay}</span>
                  </div>
                  <div className="col-sm-2">
                    <div className="grid-item">
                      <span className="numero-vuelo">#{flightNumber}</span>
                      <br />
                      <span className="city-name">{originA}</span>
                      <br />
                      <span className="hora">{timeDep}</span>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="grid-item">
                      <br />
                      <span className="city-name">{destinationD}</span>
                      <br />
                      <span className="hora">{timeArr}</span>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    {" "}
                    <br />
                    <span className="hora">{totalTime}</span>
                  </div>

                  <div className="col-sm-2">
                    <div className="grid-item">
                      <br />
                      <span className="numero-vuelo">
                        {!selectedFirstClass ? "Economy Class" : "First Class"}
                      </span>
                      <hr className="clase-vuelo-hr" />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="grid-item">
                      <span
                        className="numero-vuelo"
                        style={{ fontWeight: "bold" }}
                      >
                        Carrier
                      </span>
                      <br />
                      <span className="numero-vuelo">{ferryName}</span>
                      <hr className="clase-vuelo-hr" />
                    </div>
                  </div>
                </div>

                <br />
                <div style={{ display: isOW ? "none" : "block" }}>
                  <div className="row">
                    <div className="col-sm-2" style={{ paddingTop: "3%" }}>
                      <span className="fecha-salida">{weekDayRet}</span>
                      <br />
                      <span className="fecha-salida">{monthDayRet}</span>
                    </div>
                    <div className="col-sm-2">
                      <div className="grid-item">
                        <span className="numero-vuelo">#{retFlightNumber}</span>
                        <br />
                        <span className="city-name">{destinationD}</span>
                        <br />
                        <span className="hora">{retTimeDep}</span>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="grid-item">
                        <br />
                        <span className="city-name">{originA}</span>
                        <br />
                        <span className="hora">{retTimeArr}</span>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      {" "}
                      <br />
                      <span className="hora">{totalTimeRet}</span>
                    </div>

                    <div className="col-sm-2">
                      <div className="grid-item">
                        <br />
                        <span className="numero-vuelo">
                          {!selectedFirstClass
                            ? "Economy Class"
                            : "First Class"}
                        </span>
                        <hr className="clase-vuelo-hr" />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="grid-item">
                        <br />
                        <span className="numero-vuelo">{ferryName}</span>
                        <hr className="clase-vuelo-hr" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-3" style={{ Width: "356px" }}>
                <br />
                <div className="row" style={{ marginRight: "2px" }}>
                  <div className="column-price">
                    <div className="inner-row">
                      <div
                        className="inner-column"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        Ferry(Adult):{" "}
                      </div>
                      <div
                        className="inner-column-number"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        {alllocation.state.adultCount}{" "}
                      </div>
                    </div>
                    <div className="inner-row">
                      <div
                        className="inner-column"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        Ferry(Infant):{" "}
                      </div>
                      <div
                        className="inner-column-number"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        {alllocation.state.infantCount}{" "}
                      </div>
                    </div>
                    <div className="inner-row">
                      <div
                        className="inner-column"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        Ferry(Children Under 7):{" "}
                      </div>
                      <div
                        className="inner-column-number"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        {alllocation.state.ChildrenUnderCount}{" "}
                      </div>
                    </div>
                    <div className="inner-row">
                      <div
                        className="inner-column"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        Ferry(Children 7-11):{" "}
                      </div>
                      <div
                        className="inner-column-number"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        {alllocation.state.Children}{" "}
                      </div>
                    </div>
                    <hr />
                    <div className="inner-row">
                      <div
                        className="inner-column"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        Ferry(No. of Passenger):{" "}
                      </div>
                      <div
                        className="inner-column-number"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        {totalPax}{" "}
                      </div>
                    </div>
                    <hr />
                    <div className="inner-row">
                      <div
                        className="inner-column"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        Fare Passenger:{" "}
                      </div>
                      <div
                        className="inner-column-number"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        $ {paxFareAmount}{" "}
                      </div>
                    </div>
                    {/* <div className="inner-row">
                      <div className="inner-column"> Passenger(s) </div>
                      <div className="inner-column-number"> x{totalPax} </div>
                    </div> */}
                    <hr />
                    <div className="inner-row">
                      <div
                        className="inner-column"
                        style={{ fontSize: "15px" }}
                      >
                        Total Trip:{" "}
                      </div>
                      <div
                        className="inner-column-number"
                        style={{ fontSize: "15px" }}
                      >
                        <strong>$ {paxFareAmount}</strong>
                      </div>
                    </div>
                    {/*<div className="inner-row" style={{ fontsize: "10px" }}>*/}
                    {/*  {" "}*/}
                    {/*  *Additional Cuba taxes and fees to be collected at airport*/}
                    {/*  upon departure{" "}*/}
                    {/*</div>*/}
                  </div>

                  {/*<div style={{ padding: "10px" }}>*/}
                  {/*  <button*/}
                  {/*    className="btn btn-danger"*/}
                  {/*    style={{*/}
                  {/*      fontWeight: "bold",*/}
                  {/*      display: horizentalLine ? "none" : "block",*/}
                  {/*    }}*/}
                  {/*    onClick={ClickContinue}*/}
                  {/*  >*/}
                  {/*    Continue*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </Card>
          {/**Passnger infoamtion */}

          <br />
          <Card
            style={{
              borderRadius: "5px",
              background: "transparent",
              display: !horizentalLine ? "none" : "block",
            }}
          >
            <br />
            <div className="row">
              <div className="col-sm-9">
                <Card
                  style={{
                    borderRadius: "5px",
                    background: "transparent",
                    width: "100%",
                    marginLeft: "0%",
                  }}
                >
                  <form
                    onSubmit={handleShow}
                    className="was-validated"
                    autoComplete="off"
                  >
                    <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>

                    <br />

                    <div className="row">
                      <div className="col-sm-12">
                        {/*{isLoading ? (*/}
                        {/*    ""*/}
                        {/*) : (*/}
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleShow}
                          style={{
                            background: "rgb(2, 36, 68)",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Proceed to Checkout
                        </button>
                        {/*)}*/}
                        {isLoadingPayment ? (
                          <div>
                            <img
                              className="overlay spinner"
                              style={{
                                width: "400px",
                                height: "250px",
                                backgroundColor: "transparent",
                              }}
                              src="./loading-24.gif"
                              alt=""
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/*Payment checkout...*/}
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Payment Information</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-sm-12 form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Card Number"
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderBottomColor: "black",
                                borderRadius: "0px",
                              }}
                              name="txtCardNumber"
                              id="txtCardNumber"
                              onChange={handleCardNumber}
                            />
                            <label
                              htmlFor="floatingInputCardNumber"
                              className="form-label"
                              style={{
                                fontweight: "bold",
                                color: "rgb(2, 36, 68)",
                              }}
                            >
                              Card Number *
                            </label>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-sm-4 form-floating">
                            <select
                              className="form-select"
                              aria-label="select example"
                              id="ddlMonth"
                              name="ddlMonth"
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderBottomColor: "black",
                                borderRadius: "0px",
                              }}
                            >
                              <option value="">--Month--</option>
                              <option value="1">Jan</option>
                              <option value="2">Feb</option>
                              <option value="3">Mar</option>
                              <option value="4">Apr</option>
                              <option value="5">May</option>
                              <option value="6">Jun</option>
                              <option value="7">Jul</option>
                              <option value="8">Aug</option>
                              <option value="9">Sep</option>
                              <option value="10">Oct</option>
                              <option value="11">Nov</option>
                              <option value="12"> Dec</option>
                            </select>
                            <label
                              htmlFor="floatingInputMonth"
                              className="form-label"
                              style={{
                                fontweight: "bold",
                                color: "rgb(2, 36, 68)",
                              }}
                            >
                              Month *
                            </label>
                          </div>
                          <div className="col-sm-4 form-floating">
                            <select
                              onChange={(e) => setYearListItem(e.target.value)}
                              value={yearListItem}
                              className="form-select"
                              aria-label="select example"
                              id="ddlYear"
                              name="ddlYear"
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderBottomColor: "black",
                                borderRadius: "0px",
                              }}
                            >
                              <option value="">--Year--</option>
                              {yearList.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                            <label
                              htmlFor="floatingInputYear"
                              className="form-label"
                              style={{
                                fontweight: "bold",
                                color: "rgb(2, 36, 68)",
                              }}
                            >
                              Year *
                            </label>
                          </div>
                          <div className="col-sm-4 form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Card Code"
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderBottomColor: "black",
                                borderRadius: "0px",
                              }}
                              name="txtCardCode"
                              id="txtCardCode"
                              onChange={handleCardCode}
                            />
                            <label
                              htmlFor="floatingInputCardCode"
                              className="form-label"
                              style={{
                                fontweight: "bold",
                                color: "rgb(2, 36, 68)",
                              }}
                            >
                              Card Code *
                            </label>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-sm-12 form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderBottomColor: "black",
                                borderRadius: "0px",
                              }}
                              name="txtFirstName"
                              id="txtFirstName"
                              value={paxName}
                              readOnly
                            />
                            `{" "}
                            <label
                              htmlFor="floatingInputFirstName"
                              className="form-label"
                              style={{
                                fontweight: "bold",
                                color: "rgb(2, 36, 68)",
                              }}
                            >
                              Name *
                            </label>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-sm-6 form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip"
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderBottomColor: "black",
                                borderRadius: "0px",
                              }}
                              name="txtZip"
                              id="txtZip"
                              onChange={handleZipCode}
                            />
                            <label
                              htmlFor="floatingInputZip"
                              className="form-label"
                              style={{
                                fontweight: "bold",
                                color: "rgb(2, 36, 68)",
                              }}
                            >
                              Zip
                            </label>
                          </div>
                          <div className="col-sm-6 form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Amount"
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderBottomColor: "black",
                                borderRadius: "0px",
                              }}
                              name="txtAmount"
                              id="txtAmount"
                              value={totalPrice}
                              readOnly
                            />
                            <label
                              htmlFor="floatingInputAmount"
                              className="form-label"
                              style={{
                                fontweight: "bold",
                                color: "rgb(2, 36, 68)",
                              }}
                            >
                              Amount
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-sm-12"
                            style={{ padding: "10px" }}
                          >
                            {isLoadingPayment ? (
                              ""
                            ) : (
                              <button
                                type="submit"
                                style={{
                                  background: "rgb(2, 36, 68",
                                  color: "white",
                                  fontweight: "bold",
                                }}
                                className="form-control btn btn-primary"
                                onClick={OnPaymentCheckOut}
                              >
                                Proceed to Payment
                              </button>
                            )}
                            {isLoadingPayment ? (
                              <div>
                                <img
                                  className="overlay spinner"
                                  style={{ width: "150px", height: "150px" }}
                                  src="/Payment.gif"
                                  alt=""
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                    {/*End...*/}
                  </form>
                </Card>
              </div>
              <div className="col-sm-3">
                <Card
                  style={{
                    borderRadius: "5px",
                    background: "transparent",
                    width: "100%",
                    marginLeft: "0%",
                  }}
                >
                  <Card.Title
                    style={{
                      color: "rgb(2, 36, 68)",
                      fontWeight: "bold",
                      paddingTop: "16px",
                    }}
                  >
                    {originLabel.replace(/ *\([^)]*\) */g, "")} to{" "}
                    {destinationLabel.replace(/ *\([^)]*\) */g, "")}
                  </Card.Title>
                  <hr />
                  <div className="row">
                    <div
                      className="col-sm-7"
                      style={{ color: "rgb(2, 36, 68)", fontWeight: "bold" }}
                    >
                      Ferrt details FY:{flightNumber}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <img src="/ship.png" alt=""></img>
                      <br />
                      {weekDay} {monthDay}
                      <br />
                      {timeDep}
                      <br />
                      {originLabel}
                    </div>
                    <div className="col-sm-4">
                      <img src="/ship.png" alt=""></img>
                      <br />
                      {weekDay} {monthDay}
                      <br />
                      {timeArr}
                      <br />
                      {destinationLabel}
                    </div>
                    <div className="col-sm-4">
                      Duration
                      <br />
                      {totalTime}
                      <br />
                      Nonstop
                    </div>
                  </div>
                  {/*<div className="row">*/}
                  {/*    <div className="col-sm-4">*/}

                  {/*    </div>*/}
                  {/*    <div className="col-sm-4">*/}

                  {/*    </div>*/}
                  {/*    <div className="col-sm-4">*/}

                  {/*    </div>*/}
                  {/*</div>*/}
                  {/*<div className="row">*/}
                  {/*    <div*/}
                  {/*        className="col-sm-6"*/}
                  {/*        style={{ color: "rgb(2, 36, 68)" }}*/}
                  {/*    >*/}

                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="col-sm-6"*/}
                  {/*        style={{ color: "rgb(2, 36, 68)" }}*/}
                  {/*    >*/}

                  {/*    </div>*/}

                  {/*</div>*/}

                  <div className="row">
                    <div
                      className="col-sm-8"
                      style={{ color: "rgb(2, 36, 68)", fontWeight: "bold" }}
                    >
                      {" "}
                      Operated By: {ferryName}
                    </div>
                  </div>
                  <br />
                  <div style={{ display: isOW ? "none" : "block" }}>
                    <Card.Title
                      style={{ color: "rgb(2, 36, 68)", fontWeight: "bold" }}
                    >
                      {destinationLabel.replace(/ *\([^)]*\) */g, "")} to{" "}
                      {originLabel.replace(/ *\([^)]*\) */g, "")}
                    </Card.Title>
                    <hr />
                    <div className="row">
                      <div
                        className="col-sm-7"
                        style={{ color: "rgb(2, 36, 68)", fontWeight: "bold" }}
                      >
                        Ferrt details FY:{retFlightNumber}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <img src="/ship.png" alt=""></img>
                        <br />
                        {weekDayRet} {monthDayRet}
                        <br />
                        {retTimeDep}
                        <br />
                        {destinationLabel}
                      </div>
                      <div className="col-sm-4">
                        <img src="/ship.png" alt=""></img>
                        <br />
                        {weekDayRet} {monthDayRet}
                        <br />
                        {retTimeArr}
                        <br />
                        {originLabel}
                      </div>
                      <div className="col-sm-4">
                        Duration
                        <br />
                        {totalTimeRet}
                        <br />
                        Nonstop
                      </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="col-sm-4">*/}

                    {/*    </div>*/}
                    {/*    <div className="col-sm-4">*/}

                    {/*    </div>*/}
                    {/*    <div className="col-sm-4">*/}

                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*        <div className="col-sm-4"></div>*/}
                    {/*        <div className="col-sm-4"></div>*/}

                    {/*    <div className="col-sm-4"></div>*/}
                    {/*</div>*/}

                    <div className="row">
                      <div
                        className="col-sm-8"
                        style={{ color: "rgb(2, 36, 68)", fontWeight: "bold" }}
                      >
                        {" "}
                        Operated By: {ferryName}
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="row">
                    <div
                      className="col-sm-8"
                      style={{ color: "rgb(2, 36, 68)", fontWeight: "bold" }}
                    >
                      Ferry ({totalPax} Passenger)
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <br />
          </Card>

          {/**END Passenegr */}
        </Card>
        {/** Footer */}
        <br />
        <div className="row" style={{ backgroundColor: "rgb(2, 36, 68)" }}>
          <div
            className="col-sm-12"
            style={{
              color: "white",
              fontFamily: "LufgaRegular",
              fontSize: "15px",
            }}
          >
            {" "}
            © 2023 Copyright All Rights Reserved, Airmax System
          </div>
        </div>
      </Container>
    </>
  );
}
