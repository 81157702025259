export const destinAirport = [
    {
        label: "Road Town (RDT)",
        value: "RDT",
    },
  {
    label: "Charlotte Amalie (CHA)",
    value: "CHA",
    },
    {
        label: "STT-Cyril E. King International-Charlotte Amalie",
        value: "STT",
    },
    {
        label: "Road Town (EIS)",
        value: "EIS",
    },
    {
        label: "Charlotte Amalie (SST)",
        value: "SST",
    },
    
];
export default destinAirport;
