import { NavLink } from "react-router-dom";

export default function FooterItem() {
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    2023 © Copyright All Rights Reserved
                    <NavLink to="http://www.airmaxsystem.com" style={{ textDecoration: "none", color: "#ffff" }} target="_blank" rel="noreferrer">Airmax System</NavLink>
                </div>
            </div>
        </>
    )
}   