import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
//import MaterialIcon from "material-icons-react";
import { useTranslation } from "react-i18next";
import "../css/Flightdetails.css";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import MainURL from "../AirportList/MainUrl";
//import axios from 'axios';
//import FooterItem from "../components/FooterItem";

let hasInit = false;
var today = new Date();
let departDate = new Date();
let returnDate = new Date();
function FlightDetails(props) {
  const loaction = useLocation();
  const [liveUrl] = useState(MainURL[0]);
  //console.log(loaction);
  const regex = /[$,]/g;
  const [active, setActive] = useState(false);
  const [activeFirst, setActiveFirstClass] = useState(false);
  const [select, setSelect] = useState(false);
  const [amount, setAmount] = useState("0.00");
  const [disableContinue, setDisableContinue] = useState(true);
  const [alllocation] = useState(loaction);
  const [isSelected, setIsSelected] = useState(false);
  const [departData, setDefaultDep] = useState([]);
  const [returnData, setDefaultRet] = useState([]);
  const [flightID, setFlightId] = useState("000");
  const [flightNumber, setFlightNumber] = useState("000");
  const [TimeArr, setTimeArr] = useState("12:00 AM");
  const [TimeDep, setTimeDep] = useState("12:00 AM");
  const [FerryDate, setFerryDate] = useState("");
  const [classesEcono, setClassesEcono] = useState(false);
  const [firstClasses, setFirstClasses] = useState(false);
  const [ferryName, setFerryName] = useState("");
  const [retflightID, setRetFlightId] = useState("000");
  const [retFlightNumber, setRetFlightNumber] = useState("000");
  const [retTimeArr, setRetTimeArr] = useState("12:00 AM");
  const [retTimeDep, setRetTimeDep] = useState("12:00 AM");
  const [retFerryDate, setRetFerryDate] = useState("");
  const [fareListAmount, setTotalFare] = useState("00.00");
  const [isFlightLoading, setFlightLoading] = useState(false);
  const [retCompany, setRetComapny] = useState("");
  // const [selectedDate, setSelectedDate] = useState(null);

  const optMonth = { month: "short", day: "numeric" };
  const optWeekdays = { weekday: "long" };

  // alert(alllocation.state.isRoundTrip ? "true" : "false");
  let selectedEconomyFirstClass = "$0.00";
  let adultCount = alllocation.state.countAdult;
  let infantCount = alllocation.state.countInfant;
  let ChildrenUnderCount = alllocation.state.countChildrenUnder;
  let Children = alllocation.state.countChildren;
  let TotalCountPax = alllocation.state.totalCount;
  let OneWay = alllocation.state.isOneWay;
  if (!hasInit) {
    departDate = new Date(alllocation.state.selectesDateDep);
    returnDate = new Date(alllocation.state.selectesDateRet);
    hasInit = true;
  }
  let origin = alllocation.state.selectvalOriginCity;
  let destination = alllocation.state.selectvalDestinationCity;
  let originLabel = alllocation.state.selectvalOriginCityLabel;
  let destinationLabel = alllocation.state.selectvalDestinationCityLabel;
  //let date = departDate.toISOString().split('T')[0];

  //Language translation..
  const { t } = useTranslation("translation");
  //END

  const increasedDate1 = new Date(departDate);
  increasedDate1.setDate(departDate.getDate() + 1);
  const increasedDate2 = new Date(departDate);
  increasedDate2.setDate(departDate.getDate() + 2);
  const increasedDate3 = new Date(departDate);
  increasedDate3.setDate(departDate.getDate() + 3);

  const decreasedDate1 = new Date(departDate);
  decreasedDate1.setDate(departDate.getDate() - 1);
  const decreasedDate2 = new Date(departDate);
  decreasedDate2.setDate(departDate.getDate() - 2);
  const decreasedDate3 = new Date(departDate);
  decreasedDate3.setDate(departDate.getDate() - 3);

  const increasedDateret1 = new Date(returnDate);
  increasedDateret1.setDate(returnDate.getDate() + 1);
  const increasedDateret2 = new Date(returnDate);
  increasedDateret2.setDate(returnDate.getDate() + 2);
  const increasedDateret3 = new Date(returnDate);
  increasedDateret3.setDate(returnDate.getDate() + 3);

  const decreasedDateret1 = new Date(returnDate);
  decreasedDateret1.setDate(returnDate.getDate() - 1);
  const decreasedDateret2 = new Date(returnDate);
  decreasedDateret2.setDate(returnDate.getDate() - 2);
  const decreasedDateret3 = new Date(returnDate);
  decreasedDateret3.setDate(returnDate.getDate() - 3);

  //

  const handleClickEnconmy = (parameter) => (e) => {
    e.preventDefault();
    var id = parameter;
    let hasActiveBG = false;
    selectedEconomyFirstClass = document.getElementById(parameter);
    let flightNumber = document.getElementById(
      "txtInputFields-" + parameter
    ).value;
    let reservationIDList = document.getElementById(
      "txtInputFieldReservationIDLists-" + parameter
    ).value;
    let timeArr = document.getElementById("timeArrs-" + parameter).value;
    let timeDep = document.getElementById("timeDeps-" + parameter).value;
    let ferryDate = document.getElementById("ferryDates-" + parameter).value;
    let fareTotal = document.getElementById("fareLists-" + parameter).value;
    let ferryName = document.getElementById("companyName-" + parameter).value;
    setFlightNumber(flightNumber);
    setFlightId(reservationIDList);
    setTimeArr(timeArr);
    setTimeDep(timeDep);
    setFerryDate(ferryDate);
    setClassesEcono(true);
    setTotalFare(fareTotal);
    setFerryName(ferryName);
    if (
      document
        .getElementById(parameter)
        .classList.contains("bg-selectedColorcard")
    ) {
      hasActiveBG = true;
      setActive(false);
    } else {
      hasActiveBG = false;
      setActive(true);
    }

    if (!alllocation.state.isOneWay) {
      setAmount(selectedEconomyFirstClass.value);
      setActiveFirstClass(false);
      setDisableContinue(true);
    } else {
      setAmount(selectedEconomyFirstClass.value);
      setActiveFirstClass(false);
      setDisableContinue(false);
    }
    RemoveBtnBackground("Economy");
    RemoveBtnBackground("FirstClass");

    if (!hasActiveBG) {
      document.getElementById(parameter).classList.remove("bg-colorcard");
      document.getElementById(parameter).classList.add("bg-selectedColorcard");
    }
    setActiveFirstClass(false);
  };

  const handleClickFirstClass = (parameter) => (e) => {
    selectedEconomyFirstClass = document.getElementById(parameter);
    let flightNumber = document.getElementById(
      "txtInputField-" + parameter
    ).value;
    let reservationIDList = document.getElementById(
      "txtInputFieldReservationIDList-" + parameter
    ).value;
    let timeArr = document.getElementById("timeArr-" + parameter).value;
    let timeDep = document.getElementById("timeDep-" + parameter).value;
    let ferryDate = document.getElementById("ferryDate-" + parameter).value;
    let fareTotal = document.getElementById("fareList-" + parameter).value;
    let ferryName = document.getElementById("companyName-" + parameter).value;
    setFlightNumber(flightNumber);
    setFlightId(reservationIDList);
    setTimeArr(timeArr);
    setTimeDep(timeDep);
    setFerryDate(ferryDate);
    setFirstClasses(true);
    setTotalFare(fareTotal);
    setFerryName(ferryName);
    let hasActiveBG = false;
    if (
      document
        .getElementById(parameter)
        .classList.contains("bg-selectedColorcard")
    ) {
      setActiveFirstClass(false);
      hasActiveBG = true;
    } else {
      hasActiveBG = false;
      setActiveFirstClass(true);
    }

    if (!alllocation.state.isOneWay) {
      setAmount(selectedEconomyFirstClass.value);
      setActive(false);
      setDisableContinue(true);
    } else {
      setAmount(selectedEconomyFirstClass.value);
      setActive(false);
      setDisableContinue(false);
    }
    RemoveBtnBackground("FirstClass");
    RemoveBtnBackground("Economy");

    if (!hasActiveBG) {
      document.getElementById(parameter).classList.remove("bg-colorcard");
      document.getElementById(parameter).classList.add("bg-selectedColorcard");
    }
    setActive(false);
  };

  function RemoveBtnBackground(clsName) {
    var elements = document.getElementsByClassName(clsName);
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add("bg-colorcard");
      elements[i].classList.remove("bg-selectedColorcard");
    }
  }

  const handleClickSelect = (parameter) => (e) => {
    var selectedRT = document.getElementById(parameter);
    let hasActiveBG = false;

    let btnReturnreservationIDList = document.getElementById(
      "btnReturnInputFieldReservationIDList-" + parameter
    ).value;
    let returnFlightNumber = document.getElementById(
      "returntxtInputField-" + parameter
    ).value;
    let returnTimeArr = document.getElementById(
      "returntimeArr-" + parameter
    ).value;
    let returnTimeDep = document.getElementById(
      "returntimeDep-" + parameter
    ).value;
    let returnFerryDate = document.getElementById(
      "returnferryDate-" + parameter
    ).value;
    let returnFerryCompany = document.getElementById(
      "companyName-" + parameter
    ).value;
    setRetFlightId(btnReturnreservationIDList);
    setRetFlightNumber(returnFlightNumber);
    setRetTimeArr(returnTimeArr);
    setRetTimeDep(returnTimeDep);
    setRetFerryDate(returnFerryDate);
    setRetComapny(returnFerryCompany);
    let totalAmount = document.getElementById("totalAmount");
    var amt = totalAmount.innerHTML.replace(regex, "");
    if (amt > 0) {
      if (
        document
          .getElementById(parameter)
          .classList.contains("bg-selectedColorcard")
      ) {
        setSelect(false);
        hasActiveBG = true;
        setDisableContinue(true);
      } else {
        hasActiveBG = false;
        setSelect(true);
      }

      RemoveBtnBackground("Select");

      if (!hasActiveBG) {
        document.getElementById(parameter).classList.remove("bg-colorcard");
        document
          .getElementById(parameter)
          .classList.add("bg-selectedColorcard");
        setDisableContinue(false);
      }
      //if (select === true) {
      //    setDisableContinue(true);
      //}
      //else {
      //    setDisableContinue(false);
      //}

      //setSelect(!select);
    } else {
      alert("Please select amount !");
    }
  };

  const navigate = useNavigate();

  const handleContinue = () => {
    if (alllocation.state.isRoundTrip) {
      if (returnDate <= departDate) {
        alert("Depart date must be lesser than return date");
        setFlightLoading(false);
        return;
      }
    }
    if (ferryName !== retCompany) {
      alert("Booking is not allowed !");
      return;
    }
    navigate("/PaymentProcess", {
      replace: true,
      state: {
        origin,
        destination,
        adultCount,
        infantCount,
        ChildrenUnderCount,
        Children,
        TotalCountPax,
        flightNumber,
        flightID,
        TimeArr,
        TimeDep,
        FerryDate,
        retflightID,
        firstClasses,
        classesEcono,
        ferryName,
        retFlightNumber,
        retFerryDate,
        retTimeArr,
        retTimeDep,
        fareListAmount,
        OneWay,
        originLabel,
        destinationLabel,
      },
    });
  };

  //const fetchOneWaySmith = async () => {
  //    var url = "https://localhost:44370/api/FerrySearch/GetOneWayFerries";
  //    var myHeaders = new Headers();
  //    myHeaders.append("Content-Type", "application/json");
  //    myHeaders.append("ApiKey", "42DF1809-B8D9-4FC0-A04B-B40508F499D4");
  //    myHeaders.append("Access-Control-Allow-Origin", "*");

  //    var raw = JSON.stringify({
  //        DepartDate: departDate.toDateString(),
  //        DepartOrigin: origin,
  //        DepartDestination: destination,
  //        AdultCount: adultCount,
  //        InfantCount: infantCount,
  //        ChildCount: Children,
  //        SeniorCount: "0",
  //        Unaccompanied5to11Count: "0",
  //        Unaccompanied12to15Count: "0",
  //        IsDepartFirstClass: "false",
  //        AddInsurance: "false",
  //    });

  //    try {
  //        let response = await fetch(url, {
  //            method: "POST",
  //            headers: {
  //                "Access-Control-Allow-Origin": "*",
  //                "Content-Type": "application/json",
  //                ApiKey: "42DF1809-B8D9-4FC0-A04B-B40508F499D4",
  //            },
  //            body: raw,
  //        });
  //        var d = await response.json();
  //        //const array = JSON.stringify(d);
  //        //console.log('final response data', d);
  //        return d;
  //    } catch (error) {
  //
  //        console.error("Error fetching data:", error);
  //    }
  //};

  //const fetchRoundTripSmith = async () => {
  //    var url = "https://localhost:44370/api/FerrySearch/GetRoundTripFerries";
  //    var myHeaders = new Headers();
  //    myHeaders.append("Content-Type", "application/json");
  //    myHeaders.append("ApiKey", "42DF1809-B8D9-4FC0-A04B-B40508F499D4");
  //    myHeaders.append("Access-Control-Allow-Origin", "*");

  //    var raw = JSON.stringify({
  //        DepartDate: departDate.toDateString(),
  //        DepartOrigin: origin,
  //        DepartDestination: destination,
  //        AdultCount: adultCount,
  //        InfantCount: infantCount,
  //        ChildCount: Children,
  //        SeniorCount: "0",
  //        Unaccompanied5to11Count: "0",
  //        Unaccompanied12to15Count: "0",
  //        IsDepartFirstClass: "false",
  //        AddInsurance: "false",
  //        ReturnDate: returnDate.toDateString(),
  //        ReturnOrigin: destination,
  //        ReturnDestination: origin,
  //        IsReturnFirstClass: "false",
  //    });

  //    try {
  //        let response = await fetch(url, {
  //            method: "POST",
  //            headers: {
  //                "Access-Control-Allow-Origin": "*",
  //                "Content-Type": "application/json",
  //                ApiKey: "42DF1809-B8D9-4FC0-A04B-B40508F499D4",
  //            },
  //            body: raw,
  //        });
  //        var d = await response.json();
  //        //
  //        //setApiData(d.Table1);
  //        //const combinedJsonData = convertTablesToJson(d.Table1, d.Table2);
  //        //const array = JSON.stringify(d);
  //        console.log("final response data", d);
  //        return d;
  //    } catch (error) {
  //
  //        console.error("Error fetching data:", error);
  //    }
  //};

  //const fetchOneWayRTFF = async () => {
  //    var url = "https://localhost:44322/api/FerrySearch/GetOneWayFerries";
  //    var myHeaders = new Headers();
  //    myHeaders.append("Content-Type", "application/json");
  //    myHeaders.append("ApiKey", "42DF1809-B7D1-4FC0-A04B-B40508F400D6");
  //    myHeaders.append("Access-Control-Allow-Origin", "*");

  //    var raw = JSON.stringify({
  //        DepartDate: departDate.toDateString(),
  //        DepartOrigin: origin,
  //        DepartDestination: destination,
  //        AdultCount: adultCount,
  //        InfantCount: infantCount,
  //        ChildCount: Children,
  //        SeniorCount: "0",
  //        Unaccompanied5to11Count: "0",
  //        Unaccompanied12to15Count: "0",
  //        IsDepartFirstClass: "false",
  //        AddInsurance: "false",
  //    });

  //    try {
  //        let response = await fetch(url, {
  //            method: "POST",
  //            headers: {
  //                "Access-Control-Allow-Origin": "*",
  //                "Content-Type": "application/json",
  //                ApiKey: "42DF1809-B7D1-4FC0-A04B-B40508F400D6",
  //            },
  //            body: raw,
  //        });
  //        var d = await response.json();
  //        //const array = JSON.stringify(d);
  //        //console.log('final response data', d);
  //        return d;
  //    } catch (error) {
  //
  //        console.error("Error fetching data:", error);
  //    }
  //};

  //const fetchRoundTripRTFF = async () => {
  //    var url = "https://localhost:44322/api/FerrySearch/GetRoundTripFerries";
  //    var myHeaders = new Headers();
  //    myHeaders.append("Content-Type", "application/json");
  //    myHeaders.append("ApiKey", "42DF1809-B7D1-4FC0-A04B-B40508F400D6");
  //    myHeaders.append("Access-Control-Allow-Origin", "*");

  //    var raw = JSON.stringify({
  //        DepartDate: departDate.toDateString(),
  //        DepartOrigin: origin,
  //        DepartDestination: destination,
  //        AdultCount: adultCount,
  //        InfantCount: infantCount,
  //        ChildCount: Children,
  //        SeniorCount: "0",
  //        Unaccompanied5to11Count: "0",
  //        Unaccompanied12to15Count: "0",
  //        IsDepartFirstClass: "false",
  //        AddInsurance: "false",
  //        ReturnDate: returnDate.toDateString(),
  //        ReturnOrigin: destination,
  //        ReturnDestination: origin,
  //        IsReturnFirstClass: "false",
  //    });

  //    try {
  //        let response = await fetch(url, {
  //            method: "POST",
  //            headers: {
  //                "Access-Control-Allow-Origin": "*",
  //                "Content-Type": "application/json",
  //                ApiKey: "42DF1809-B7D1-4FC0-A04B-B40508F400D6",
  //            },
  //            body: raw,
  //        });
  //        var d = await response.json();
  //        //
  //        //setApiData(d.Table1);
  //        //const combinedJsonData = convertTablesToJson(d.Table1, d.Table2);
  //        //const array = JSON.stringify(d);
  //        //console.log('final response data', d);
  //        return d;
  //    } catch (error) {
  //
  //        console.error("Error fetching data:", error);
  //    }
  //};

  const fetchOneWayCQ = async () => {
    var url = liveUrl.liveUrl + "FerrySearch/GetOneWayFerries";
    //var myHeaders = new Headers();
    //myHeaders.append("Content-Type", "application/json");
    //myHeaders.append("ApiKey", "42DF1809-B7D1-4FC0-A04B-B40508F400D6");
    //myHeaders.append("Access-Control-Allow-Origin", "*");

    var raw = JSON.stringify({
      DepartDate: departDate,
      DepartOrigin: origin,
      DepartDestination: destination,
      AdultCount: adultCount,
      InfantCount: infantCount,
      ChildCount: Children,
      SeniorCount: "0",
      Unaccompanied5to11Count: "0",
      Unaccompanied12to15Count: "0",
      IsDepartFirstClass: false,
      AddInsurance: false,
      Company: 0,
    });

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          // ApiKey: "42DF1809-B7D1-4FC0-A04B-B40508F400D6",
        },
        body: raw,
      });
      var data = await response.json();
      //const array = JSON.stringify(d);
      console.log("final response data OW", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRoundTripCQ = async () => {
    var url = liveUrl.liveUrl + "FerrySearch/GetRoundTripFerries";
    //var myHeaders = new Headers();
    //myHeaders.append("Content-Type", "application/json");
    //myHeaders.append("ApiKey", "42DF1809-B7D1-4FC0-A04B-B40508F400D6");
    //myHeaders.append("Access-Control-Allow-Origin", "*");

    var raw = JSON.stringify({
      DepartDate: departDate,
      DepartOrigin: origin,
      DepartDestination: destination,
      AdultCount: adultCount,
      InfantCount: infantCount,
      ChildCount: Children,
      SeniorCount: "0",
      Unaccompanied5to11Count: "0",
      Unaccompanied12to15Count: "0",
      IsDepartFirstClass: firstClasses,
      AddInsurance: false,
      ReturnDate: returnDate,
      ReturnOrigin: destination,
      ReturnDestination: origin,
      IsReturnFirstClass: firstClasses,
      Company: 0,
    });

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          //    //ApiKey: "42DF1809-B7D1-4FC0-A04B-B40508F400D6",
        },
        body: raw,
      });
      var data = await response.json();
      //
      //setApiData(d.Table1);
      //const combinedJsonData = convertTablesToJson(d.Table1, d.Table2);
      //const array = JSON.stringify(d);
      console.log("final response data RT", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getFlightData = () => {
    var sample = require("../Files/JSONData/Flightlist.json");
    //console.log(sample);
    return sample;
  };

  async function fetchData() {
    debugger;
    if (alllocation.state.isRoundTrip) {
      setFlightLoading(true);
      let OWData = null;
      let RTData = null;
      let apiData = await fetchRoundTripCQ();

      if (apiData === null || apiData === undefined || apiData.status !== 200) {
        OWData = [];
        RTData = [];
      } else {
        OWData = apiData.data.table1;
        RTData = apiData.data.table2;
      }
      //if (OWData === null || OWData === undefined) OWData = [];
      //if (RTData === null || RTData === undefined) RTData = [];
      //if (OWRtff === null || OWRtff === undefined) OWData = OWSmith;
      //if (RTRtff === null || RTRtff === undefined) RTData = RTSmith;
      //if (
      //    OWSmith === null &&
      //    OWSmith === undefined &&
      //    RTSmith === null &&
      //    RTSmith === undefined &&
      //    OWRtff === null &&
      //    OWRtff === undefined &&
      //    RTRtff === null &&
      //    RTRtff === undefined
      //) {
      //    OWData = { ...OWSmith, ...OWRtff };
      //    RTData = { ...RTSmith, ...RTRtff };
      //}
      setDefaultDep(OWData);
      setDefaultRet(RTData);
      setTimeout(() => {
        setFlightLoading(false);
      }, 2000);
    } else {
      setFlightLoading(true);
      let OWData = null;
      let apiData = await fetchOneWayCQ();

      if (apiData === null || apiData === undefined || apiData.status !== 200) {
        OWData = [];
      } else {
        OWData = apiData.data.table1;
      }

      //OWData = apiData.Table1;
      //if (OWData === null || OWData === undefined) OWData = [];
      //if (OWSmith === null || OWSmith === undefined) OWData = OWRtff;
      //else if (OWRtff === null || OWRtff === undefined) OWData = OWSmith;
      //else OWData = { ...OWSmith, ...OWRtff };
      //let vd = await fetchOneWaySmith();
      //const OWData = vd.Table1;
      setDefaultDep(OWData);
      setTimeout(() => {
        setFlightLoading(false);
      }, 2000);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataDepart = (param) => async (e) => {
    e.preventDefault();
    setFlightLoading(true);
    //const dataArray = getFlightData();
    setIsSelected(!isSelected);
    let date = new Date(param);

    if (date < today) {
      alert("Depart Date is not lesser than today");
      setFlightLoading(false);
      return;
    }
    departDate = date;
    let OWData = null;
    let apiData = null;
    if (alllocation.state.isRoundTrip) {
      apiData = await fetchRoundTripCQ();
    } else {
      apiData = await fetchOneWayCQ();
    }
    if (apiData === null || apiData === undefined || apiData.status !== 200) {
      OWData = [];
    } else {
      OWData = apiData.data.table1;
    }
    //OWData = apiData.Table1;
    //if (OWData === null || OWData === undefined) OWData = [];
    //else if (OWRtff === null || OWRtff === undefined) OWData = OWSmith;
    //else OWData = { ...OWSmith, ...OWRtff };

    setDefaultDep(OWData);
    setTimeout(() => {
      setFlightLoading(false);
    }, 2000);
    //alert("Successfully Updated");

    //console.log(DepartFlightList);
  };

  const fatchDataReturn = (param) => async (e) => {
    e.preventDefault();
    //const dataArray = getFlightData();
    setFlightLoading(true);
    setIsSelected(!isSelected);
    let date = new Date(param);
    //if (date <= departDate) {
    //    alert("Return date must be greater than depart date");
    //    setFlightLoading(false);
    //    return;
    //}
    returnDate = date;

    let RTData = null;
    let apiData = await fetchRoundTripCQ();
    if (apiData === null || apiData === undefined || apiData.status !== 200) {
      RTData = [];
    } else {
      RTData = apiData.data.table2;
    }
    //RTData = apiData.Table1;
    //if (RTData === null || RTData === undefined) RTData = [];
    //let RTData = null;
    //let smithData = await fetchRoundTripSmith();
    //let rtffData = await fetchRoundTripRTFF();
    //const RTSmith = smithData.Table2;
    //const RTRtff = rtffData.Table2;
    //if (RTSmith === null || RTSmith === undefined) RTData = RTRtff;
    //else if (RTRtff === null || RTRtff === undefined) RTData = RTSmith;
    //else RTData = { ...RTSmith, ...RTRtff };

    setDefaultRet(RTData);
    setTimeout(() => {
      setFlightLoading(false);
    }, 2000);
    //alert("Successfully Updated");
    //console.log(ReturnFlightList);
  };

  return (
    <>
      <Navbar />
      <Container className="ax-container">
        <Card>
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "18px",
                  marginLeft: "0%",
                  padding: "1%",
                  lineHeight: "1em",
                  fontfamily: "LufgaBold",
                }}
              >
                {/*<MaterialIcon*/}
                {/*    icon="flight_takeoff"*/}
                {/*    color={"black !important"}*/}
                {/*/>{" "}*/}
                DEPARTURE : {originLabel} - {destinationLabel}
              </h1>
            </div>
          </div>
          {/* DateTime Card */}
          <div className="row ax-main-date">
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fetchDataDepart(decreasedDate3)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {decreasedDate3
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {decreasedDate3.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px", fontSize: "18px" }}
              onClick={fetchDataDepart(decreasedDate2)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {decreasedDate2
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {decreasedDate2.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fetchDataDepart(decreasedDate1)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {decreasedDate1
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {decreasedDate1.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fetchDataDepart(departDate)}
            >
              <Card className={true ? "bg-selectedColorcard" : "bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {departDate
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {departDate.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fetchDataDepart(increasedDate1)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {increasedDate1
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {increasedDate1.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fetchDataDepart(increasedDate2)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {increasedDate2
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {increasedDate2.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fetchDataDepart(increasedDate3)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {increasedDate3
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {increasedDate3.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
          </div>
          {/* End Datetime*/}
          <hr />
          <div className="row">
            <div className="col-sm-2">
              <h3 style={{ marginLeft: "5%", fontSize: "18px" }}>
                Ferry Number
              </h3>
            </div>
            <div className="col-sm-3">
              <h3 style={{ marginLeft: "5%", fontSize: "18px" }}>Carrier</h3>
            </div>
            <div className="col-sm-3">
              <h3
                style={{
                  fontSize: "18px",
                  fontFamily: "LufgaBold",
                  margin: "1%",
                }}
              >
                Departure
              </h3>
            </div>

            <div className="col-sm-4">
              <div
                className="btn-econamic form-control"
                style={{ width: "53%" }}
              >
                Economy Class
              </div>
            </div>
            <div className="col-sm-0" style={{ display: "none" }}>
              <Popup
                contentStyle={{ width: "340px" }}
                trigger={
                  <div
                    className="btn-econamic form-control"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    First Class
                  </div>
                }
                position="top"
              >
                <div className="row">
                  <div className="col-sm-12">
                    <h4>First Class Benefits</h4>
                  </div>
                  <div className="col-sm-12">
                    <h5>Skip the lines and secure your luggage:</h5>
                  </div>
                  <div className="col-sm-12">
                    <ul>
                      <li>1. Seat in the First Class cabin of the plane</li>
                      <li>2. Priority at check-in and boarding time</li>
                      <li>3. Up to 4 suitcases will be prioritized</li>
                    </ul>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
          <hr />
          {departData === undefined ||
          departData === null ||
          departData.length === 0 ? (
            <>
              <div className="row">
                <div>
                  <h4 style={{ fontSize: "18px" }}>No Ferry found</h4>
                </div>
              </div>
            </>
          ) : (
            <>
              {departData.map((item) => (
                <div className="row">
                  <div className="col-sm-2" style={{ fontSize: "18px" }}>
                    <h3 style={{ marginLeft: "5%", fontSize: "18px" }}>
                      {item.ferryList}
                    </h3>
                  </div>
                  <div className="col-sm-3" style={{ fontSize: "18px" }}>
                    <h3 style={{ marginLeft: "5%", fontSize: "18px" }}>
                      {item.company}
                    </h3>
                  </div>
                  <div className="col-sm-3">
                    <h3
                      style={{
                        fontSize: "18px",
                        fontFamily: "LufgaBold",
                        margin: "1%",
                      }}
                    >
                      {" "}
                      {item.timeDep} - {item.timeArr}
                    </h3>
                  </div>

                  <div className="col-sm-4">
                    <button
                      className="btn-amount form-control Economy"
                      id={item.ferryList}
                      value={item.fare}
                      onClick={handleClickEnconmy(item.ferryList)}
                      style={{ width: "53%" }}
                    >
                      <span>${OneWay ? item.fare : item.fare / 2}</span>
                      <input
                        type="hidden"
                        id={`txtInputFields-${item.ferryList}`}
                        value={item.ferryList}
                      />
                      <input
                        type="hidden"
                        id={`txtInputFieldReservationIDLists-${item.ferryList}`}
                        value={item.reservationIDList}
                      />
                      <input
                        type="hidden"
                        id={`timeDeps-${item.ferryList}`}
                        value={item.timeDep}
                      />
                      <input
                        type="hidden"
                        id={`timeArrs-${item.ferryList}`}
                        value={item.timeArr}
                      />
                      <input
                        type="hidden"
                        id={`ferryDates-${item.ferryList}`}
                        value={item.ferryDate}
                      />
                      <input
                        type="hidden"
                        id={`fareLists-${item.ferryList}`}
                        value={item.fare}
                      />
                      <input
                        type="hidden"
                        id={`companyName-${item.ferryList}`}
                        value={item.company}
                      />
                    </button>
                  </div>
                  <div className="col-sm-1" style={{ display: "none" }}>
                    <button
                      className="btn-amount form-control FirstClass"
                      value={item.firstClass}
                      id={`button-${item.ferryList}`}
                      onClick={handleClickFirstClass(
                        `button-${item.ferryList}`
                      )}
                    >
                      <span>$ {item.firstClass}</span>
                      <input
                        type="hidden"
                        id={`txtInputField-button-${item.ferryList}`}
                        value={item.ferryList}
                      />
                      <input
                        type="hidden"
                        id={`txtInputFieldReservationIDList-button-${item.ferryList}`}
                        value={item.reservationIDList}
                      />
                      <input
                        type="hidden"
                        id={`timeDep-button-${item.ferryList}`}
                        value={item.timeDep}
                      />
                      <input
                        type="hidden"
                        id={`timeArr-button-${item.ferryList}`}
                        value={item.timeArr}
                      />
                      <input
                        type="hidden"
                        id={`ferryDate-button-${item.ferryList}`}
                        value={item.ferryDate}
                      />
                      <input
                        type="hidden"
                        id={`fareList-button-${item.ferryList}`}
                        value={item.firstClass}
                      />
                      <input
                        type="hidden"
                        id={`companyName-button-${item.ferryList}`}
                        value={item.company}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
          <hr />
        </Card>
      </Container>

      {/* Start-Return flight details*/}

      <Container
        className="ax-container"
        style={{
          display: alllocation.state.isRoundTrip
            ? "true"
            : "false"
            ? "none"
            : "block",
        }}
      >
        <Card>
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "18px",
                  marginLeft: "0%",
                  padding: "1%",
                  lineHeight: "1em",
                  fontfamily: "LufgaBold",
                }}
              >
                {/*<MaterialIcon*/}
                {/*    icon="flight_land"*/}
                {/*    color={"black !important"}*/}
                {/*/>{" "}*/}
                RETURN : {destinationLabel} - {originLabel}
              </h1>
            </div>
          </div>
          {/* DateTime Card */}
          <div className="row ax-main-date">
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fatchDataReturn(decreasedDateret3)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {decreasedDateret3
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {decreasedDateret3.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fatchDataReturn(decreasedDateret2)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {decreasedDateret2
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {decreasedDateret2.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fatchDataReturn(decreasedDateret1)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {decreasedDateret1
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {decreasedDateret1.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fatchDataReturn(returnDate)}
            >
              <Card className={true ? "bg-selectedColorcard" : "bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {returnDate
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {returnDate.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fatchDataReturn(increasedDateret1)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {increasedDateret1
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {increasedDateret1.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fatchDataReturn(increasedDateret2)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {increasedDateret2
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {increasedDateret2.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-sm-2 ax-row"
              style={{ width: "180px", padding: "2px" }}
              onClick={fatchDataReturn(increasedDateret3)}
            >
              <Card className={"bg-colorcard"}>
                <Card.Body>
                  <Card.Header style={{ fontSize: "16px" }}>
                    {increasedDateret3
                      .toLocaleDateString("en-US", optWeekdays)
                      .toUpperCase()}
                    <Card.Title style={{ fontSize: "16px" }}>
                      {increasedDateret3.toLocaleDateString("en-US", optMonth)}
                    </Card.Title>
                  </Card.Header>
                </Card.Body>
              </Card>
            </div>
          </div>
          {/* End Datetime*/}
          <hr />
          <div className="row">
            <div className="col-sm-2">
              <h3 style={{ marginLeft: "5%", fontSize: "18px" }}>
                Ferry Number
              </h3>
            </div>
            <div className="col-sm-3">
              <h3 style={{ marginLeft: "5%", fontSize: "18px" }}>Carrier</h3>
            </div>
            <div className="col-sm-3">
              <h3
                style={{
                  fontSize: "18px",
                  fontFamily: "LufgaBold",
                  margin: "1%",
                }}
              >
                Return
              </h3>
            </div>

            <div className="col-sm-4"></div>
            <div className="col-sm-0"></div>
          </div>
          <hr />
          {returnData === undefined ||
          returnData === null ||
          returnData.length === 0 ? (
            <>
              <div className="row">
                <div>
                  <h4 style={{ fontSize: "18px" }}>No Ferry found</h4>
                </div>
              </div>
            </>
          ) : (
            <>
              {returnData.map((item) => (
                <div className="row">
                  <div className="col-sm-2" style={{ fontSize: "18px" }}>
                    <h3 style={{ marginLeft: "5%", fontSize: "18px" }}>
                      {item.ferryList}
                    </h3>
                  </div>
                  <div className="col-sm-3" style={{ fontSize: "18px" }}>
                    <h3 style={{ marginLeft: "5%", fontSize: "18px" }}>
                      {item.company}
                    </h3>
                  </div>
                  <div className="col-sm-3">
                    <h3
                      style={{
                        fontSize: "18px",
                        fontFamily: "LufgaBold",
                        margin: "1%",
                      }}
                    >
                      {" "}
                      {item.timeDep} - {item.timeArr}
                    </h3>
                  </div>

                  <div className="col-sm-4">
                    <div
                      className="btn-amount form-control Select"
                      id={`select-${item.ferryList}`}
                      style={{
                        backgroundColor: select ? "Green" : "rgb(2, 36, 68)",
                        width: "53%",
                      }}
                      onClick={handleClickSelect(`select-${item.ferryList}`)}
                    >
                      <span>${item.fare / 2}</span>
                    </div>
                    <input
                      type="hidden"
                      id={`btnReturnInputFieldReservationIDList-select-${item.ferryList}`}
                      value={item.reservationIDList}
                    />
                    <input
                      type="hidden"
                      id={`returntxtInputField-select-${item.ferryList}`}
                      value={item.ferryList}
                    />
                    <input
                      type="hidden"
                      id={`returntimeDep-select-${item.ferryList}`}
                      value={item.timeDep}
                    />
                    <input
                      type="hidden"
                      id={`returntimeArr-select-${item.ferryList}`}
                      value={item.timeArr}
                    />
                    <input
                      type="hidden"
                      id={`returnferryDate-select-${item.ferryList}`}
                      value={item.ferryDate}
                    />
                    <input
                      type="hidden"
                      id={`companyName-select-${item.ferryList}`}
                      value={item.company}
                    />
                  </div>
                  <div className="col-sm-0"></div>
                </div>
              ))}
            </>
          )}

          <hr />
        </Card>
      </Container>

      {/*End here....*/}
      {isFlightLoading ? (
        <div>
          <img
            className="overlay spinner"
            style={{
              width: "400px",
              height: "250px",
              backgroundColor: "transparent",
            }}
            src="./loading-24.gif"
            alt=""
          />
        </div>
      ) : (
        ""
      )}
      {/*Total Amount Display and Continue Button*/}
      <Container>
        <Card>
          <hr />
          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
              <h5>TOTAL</h5>
            </div>
            <div className="col-sm-4">
              <h4>
                <span id="totalAmount">$ {amount}</span>
              </h4>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-3"></div>
            <div className="col-sm-3"></div>
            <div className="col-sm-3">
              <button
                className="btn btn-danger"
                style={{ background: "rgb(2, 36, 68)" }}
                disabled={disableContinue}
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </div>
          <hr />
        </Card>
      </Container>

      {/* End here..*/}
    </>
  );
}

export default FlightDetails;
